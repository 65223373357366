import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, inject } from '@angular/core';
import { PublicDataPipe, UtilityService } from 'src/app/core/utils/tools';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from '../../camera/components/camera/camera.component';
import { TimeagoModule, TimeagoPipe } from 'ngx-timeago';
import { UserLabelComponent } from 'src/app/core/utils/user-label/user-label.component';
import { Post } from 'src/app/features/user/models/user';
import { ProfilePageService } from 'src/app/features/user/services/profile-page.service';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    UserLabelComponent,
    TimeagoModule,
  ]
})
export class HeaderUserComponent implements OnInit {

  // Services
  public notif = inject(ToastrService);
  public userService = inject(UserService);
  public apiService = inject(ApiService);
  public modal = inject(NgbModal);
  public profilePageService = inject(ProfilePageService);
  public utility = inject(UtilityService);

  // Variables
  @Input("post")
  m_currentPost?: Post;
  @Output("onEditText")
  public onEditTextChange: EventEmitter<any> = new EventEmitter<any>()
  public m_post: Post | undefined;
  public m_paramListOpen: boolean = false;

  constructor() { }

  ngOnInit() {
      this.m_post = this.m_currentPost;
  }

  /**
   * Ouvre le menu parametre
   * @param isOpen
   */
  public onParamHover(isOpen: boolean)
  {
    this.m_paramListOpen = isOpen;
  }

  /**
   * Switch entre visible par les amis ou tout le monde
   */
  public changeVisibility()
  {
    if (this.m_currentPost != null)
    {
      if (this.m_currentPost.ownerType == "user")
      {
        if (this.m_currentPost.visibleBy == "friend")
        {
          this.m_currentPost.visibleBy = "all";
        } else {
          this.m_currentPost.visibleBy = "friend";
        }
      }
      else if (this.m_currentPost.ownerType == "community")
      {
        if (this.m_currentPost.visibleBy == "member")
        {
          this.m_currentPost.visibleBy = "all";
        } else {
          this.m_currentPost.visibleBy = "member";
        }
      }
      else if (this.m_currentPost.ownerType == "event")
      {
        if (this.m_currentPost.visibleBy == "member")
        {
          this.m_currentPost.visibleBy = "all";
        } else {
          this.m_currentPost.visibleBy = "member";
        }
      }

      // Update via l'api
      this.apiService.UpdatePost(this.m_post).subscribe((request) => {
        if (request.status)
        {
          if (!this.m_post)
            return;

          this.m_post.visibleBy = request.data.visibleBy;

          this.notif.info("Votre publication est à jour");
        }
        else{
          this.notif.error("Impossible de mettre à jour votre publication")
        }
      });
    }
  }

  /**
   * Fait remonté l'info d'un changement de text
   * @param editText new text
   */
  public updateText()
  {
    this.onEditTextChange.emit(true);
  }

  /**
   * Supprime ce poste
   */
  public hidePost(post?: Post) {

    if (!post)
     return;

    post.showOnTimeline = !post.showOnTimeline;
    this.apiService.UpdatePost(post).subscribe((request) => {
      if (request.status) {
        if (post.showOnTimeline)
          this.notif.success("Le poste est visible sur votre mur.");
        else
          this.notif.success("Le poste n'est plus visible sur votre mur.");
      } else {
        this.notif.error("Erreur durant la mise à jour du poste");
      }
      this.modal.dismissAll();
    });
  }

  /**
   * Supprime ce poste
   */
  public deletePost() {
    this.profilePageService.deletePost(this.m_currentPost).subscribe((request) => {
      if (request.status) {
        this.notif.success("Poste supprimmé.");
      } else {
        this.notif.error("Erreur durant la suppression du poste");
      }
      this.modal.dismissAll();
    });
  }

  /**
   * Supprime ce poste
   */
     public deleteEventPost() {
      if (this.m_currentPost == null || this.m_currentPost?.medias == null || this.m_currentPost?.medias.length == 0)
        return;

      this.apiService.deleteMedia(this.m_currentPost?.medias[0]?.ID).subscribe((request) => {
        if (request.status) {
          this.notif.success("Poste supprimmé.");
        } else {
          this.notif.error("Erreur durant la suppression du poste");
        }
        this.modal.dismissAll();
      });
    }

  /**
   * ferme les modal
   */
  public closeModal() {
    this.modal.dismissAll();
  }

  /**
   * Ouvre le modal
   * @param modal template
   */
  public openModal(modal: any) {
    this.modal.open(modal);
  }
}
