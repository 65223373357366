export const environment = {
  production: true,
  updatePositionInterval: 60000,
  authApi: 'https://api.socialmove.com/',
  apiUrl: 'https://api.socialmove.com/api',
  fileUrl: 'https://static.sociamove.com/public/',
  socketUrl: 'https://socket.socialmove.com/',
  app: 'https://app.socialmove.com',
  config: {
    panelCupertino: {
      buttonDestroy: false,
      fastSwipeClose: false,
      initialBreak: 'bottom',
      lowerThanBottom: true,
      breaks: {
        bottom: {
          height: 70,
          enabled: true,
          bounce: true,
        },
        middle: {
          height: 350,
          enabled: true,
          bounce: true,
        },
        top: {
          height: window.innerHeight - (window.innerHeight / 2 - 350),
          enabled: true,
          bounce: true,
        },
      },
      bottomClose: false,
      bottomOffset: 0,
    },
  },
  //appVersion: require('../../package.json').version + '-dev',
};
