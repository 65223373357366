export enum UserState {
  Connected = 0,
  Busy = 1,
  Disconnected = 2,
  Custom = 3,
}

/*export class User {
    public chatWindow: ChatWindow;
    public infos: ICurrentUser;


}*/

export enum Gender {
  Man,
  Woman,
}

export class CommunitySearchRequest {
  value?: string;
  types?: number[];
  countries?: string[];
  rating?: number;
  member?: number;
  private?: boolean;
}

export class EventSearchRequest {
  value?: string;
  types?: number[];
  countries?: string[];
  rating?: number;
  member?: number;
}

export class CommentSearchRequest {
  code?: string;
  message?: string;
  pageResult?: PageResult;
  status?: boolean;
}

export class Call {
  callId?: number;
  fromUserId?: number;
  toUserId?: number;
  fromSocketId?: string;
  toSocketId?: string;
  duration?: number;
  direction?: CallDirection;
}

export enum CallDirection {
  Outgoing,
  Incoming,
}

export interface Bookmark {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  OwnerID?: number;
  OwnerType?: string;
  UserID?: number;
  State?: IsValid;
  User?: UserDetails;
}

export enum IsValid {
  Valid = 'valid',
  NotValid = 'not_valid',
}

export interface EventDetail {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  name?: string;
  map_instance?: string;
  typeId?: number;
  type?: any;
  banner?: string;
  description?: string;
  phone?: string;
  user?: UserDetails;
  owner?: number;
  postalCode?: string;
  address?: string;
  city?: string;
  address_number?: string;
  email?: string;
  memberLimit?: string;
  publicPrice?: string;
  publicationPrice?: string;
  sellUrl?: string;
  icon?: string;
  position?: any;
  positionId?: number;
  members?: any[];
  startDate?: Date;
  endDate?: Date;
  posts?: Post[];
  groups?: any[];
  favoriteColor?: string;
  ratingList?: any[];
  ratingValue?: string;
  medias?: any[];
}

export interface Activity {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  name?: string;
  map_instance?: string;
  typeId?: number;
  type?: any;
  banner?: string;
  description?: string;
  phone?: string;
  user?: UserDetails;
  owner?: number;
  postalCode?: string;
  address?: string;
  city?: string;
  address_number?: string;
  email?: string;
  memberLimit?: string;
  publicPrice?: string;
  timeranges?: any;
  publicationPrice?: string;
  sellUrl?: string;
  icon?: string;
  position?: any;
  positionId?: number;
  members?: any[];
  startDate?: string;
  endDate?: string;
  posts?: Post[];
  groups?: any[];
  favoriteColor?: string;
  ratingList?: any[];
  ratingValue?: string;
  medias?: any[];
  usersBookmark?: any[];
  bookmark?: any;
}

export class Language {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  ownerID?: number;
  shortName?: string;
  cca3?: string;
  cca2?: string;
  uiLang?: string;
}

export class Country {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  ownerID?: number;
  shortName?: string;
  cca3?: string;
  cca2?: string;
}

export interface UserDetails {
  // Model
  ID: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: Date;
  UID: number;
  email?: string;
  firstname?: string;
  lastname?: string;
  birthdayDate?: Date;
  country?: Country;
  description?: string;
  sexe?: number;
  likes?: null;
  folowers?: null;
  state?: UserState;
  profileImage?: string;
  profileBanner?: string;
  events?: any[] | null;
  friends?: UserDetails[] | null;
  notifications?: null;
  medias?: Media[] | null;
  posts?: Post[] | null;
  galleryCategorys?: GalleryCategory[] | null;
  token?: string;
  eventsRegistred?: null;
  prenium?: boolean;
  favoriteColor?: string;
  stripeCustomerId?: null;
  settings?: Settings | null;
  phoneNumber?: string;
  birthLocation?: string;
  conjugalStatus?: string;
  language?: Language;
  customGender?: string;
  followers?: any[];
  location?: any;
  gpsPosition?: any;
  incomingActivities?: ActivityRegister[];
  pastActivities?: ActivityRegister[];
  hasNewMessage?: boolean;
  newMessageCount?: number;
}

/**
Event register struct
*/
export interface EventRegister {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  userID?: number;
  user?: UserDetails;
  eventID?: number;
  event?: EventDetail;
  state?: string;
}

/**
Activity register struct
*/
export interface ActivityRegister {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  userID?: number;
  user?: UserDetails;
  activityID?: number;
  activity?: Activity;
  state?: string;
}

export interface Settings {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  owner?: number;
  notifZoneEnable?: boolean;
  notifZoneDistance?: number;
  profileFriendCanPost?: boolean;
  profileFacebookLink?: string;
  profileTwitterLink?: string;
  profileInstagramLink?: string;
  profileWhoSeeFuturPost?: string;
  profileWhoSendRequestFriend?: string;
  forumSignature?: string;
  filterPreferenceJson?: string;
  birthDayVisibility?: string;
  conjugalStatusVisibility?: string;
  genderVisibility?: string;
  locationVisibility?: string;
  phoneNumberVisibility?: string;
}

export interface GalleryCategory {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  medias?: any[];
  type?: string;
  name?: string;
  owner?: number;
}

export interface Like {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  owner?: number;
  user?: UserDetails;
  type?: string;
  typeTargetId?: number;
  state?: string;
}

export interface Post {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  referance?: string;
  text?: string;
  type?: string;
  likes?: Like[];
  comments?: null;
  shareds?: any[];
  userId?: number;
  owner?: number;
  ownerType?: string;
  user?: UserDetails;
  visibleBy?: string;
  showOnTimeline?: boolean;
  medias?: Media[];
  event?: EventDetail;
  activity?: Activity;
  eventId?: number;
  activityId?: number;
  hotspotId?: number;
  commentCount?: number;
}

export interface Media {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  type?: ImageType;
  owner?: number;
  category?: number;
  post?: Post;
  postId?: number;
  message?: ChatMessage;
  user?: UserDetails;
  userId?: number;
  ownerType?: OwnerTypeMedia;
  event?: Event;
  data?: any;
  description?: string;
  uploadProgress?: number;
  base64?: string;
  imageType?: ImageType;
}

export enum OwnerTypeMedia {
  User = 'user',
  Hotspot = 'hotspot',
  Activity = 'activity',
}

export interface ChatMessage {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  text?: string;
  fileID?: number;
  files?: Media[];
  likes?: Like[] | null;
  Dislikes?: Like[] | null;
  FromUser?: UserDetails;
  ToUser?: UserDetails;
  FromUserID?: number;
  ToUserID?: number;
  type?: string;
  messageType?: string;
  isRead?: boolean;
}

/**
Forum struct
*/

export interface Forum {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  name?: string; // Forum name (default = community name)
  type?: string; // Notif type, friendRelation, friendRelationAccepted, eventCreated, groupInvite, eventInvite
  state?: string; // Notif state
  description?: string; // Description of notif
  communityId?: number; // Id of owner (user)
  forumCategorys?: ForumCategory[]; // Liste des category du forum
}

export interface EventPinDetail {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  eventId?: number;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  memberCount?: number;
  ratingValue?: number;
  typeId?: number;
  icon?: string;
  x?: string;
  y?: string;
  visible?: boolean;
}

export interface ActivityPinDetail {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  activityId?: number;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  memberCount?: number;
  ratingValue?: number;
  typeId?: number;
  icon?: string;
  x?: string;
  y?: string;
  visible?: boolean;
}

export interface SubCategoryFilter {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  label?: string;
  subtitle?: string;
  icon?: string;
  owner?: number;
  category?: CategoryFilter;
}

export interface CategoryFilter {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  label?: string;
  subCategory?: SubCategoryFilter[];
}

/**
ForumCategory struct
*/
export interface ForumCategory {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  name?: string; // Name of notif
  description?: string;
  reputLimit?: number;
  forumSubjects?: ForumSubject[];
  forumId?: number;
  lastSubject?: ForumSubject;
  lastMessage?: ForumAnswer;
}

export interface Follow {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  owner?: number;
  ownerType?: string;
  fromUserID?: number;
  user?: UserDetails;
}

/**
ForumSubject struct
*/
export interface ForumSubject {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  name?: string;
  description?: string;
  reputLimit?: number;
  activeMembers?: UserDetails[];
  forumAnswers?: ForumAnswer[];
  isPublic?: boolean;
  categoryId?: number;
  lastMessage?: ForumAnswer;
  pinned?: boolean;
}

export interface PageResult {
  page?: number;
  page_size?: number;
  data?: any;
  total_records?: number;
}

/**
ForumAnswer struct
*/
export interface ForumAnswer {
  ID?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DeletedAt?: null;
  ownerID?: number;
  owner?: UserDetails;
  text?: string;
  likes?: Like;
  subjectID?: number;
  index?: number;
}

export interface Rating {
  ID: number;
  CreatedAt: Date;
  UpdatedAt: Date;
  DeletedAt: null;
  userID: number;
  user: UserDetails;
  ownerID: number;
  note: number;
  type: string;
}

export interface UserGroupRelation {
  ID: number;
  CreatedAt: Date;
  UpdatedAt: Date;
  DeletedAt: null;
  userID: number;
  user: UserDetails;
  groupID: number;
  role: number;
}

export interface UserGroup {
  ID: number;
  CreatedAt: Date;
  UpdatedAt: Date;
  DeletedAt: null;
  userID: number;
  eventID: number;
  user: UserDetails;
  members: UserGroupRelation[];
  name: string;
  messages: GroupMessage[];
  description: string;
  reputation: number;
  ratingList: Rating[];
  ratingValue: string;
}

export interface GroupMessage {
  ID: number;
  CreatedAt: Date;
  UpdatedAt: Date;
  DeletedAt: null;
  text: string;
  fileID: string;
  file: Media;
  likes: Like[] | null;
  Dislikes: Like[] | null;
  FromUser: UserDetails;
  ToGroup: UserDetails;
  FromUserID: number;
  ToGroupId: number;
  type: string;
  messageType: string;
}

export enum ImageType {
  UserBanner = 'user_banner',
  UserMiniature = 'user_miniature',
  UserLibrary = 'user_library',
  Chat = 'chat',
  EventLibrary = 'event_library',
  EventBanner = 'event_banner',
  ActivityLibrary = 'activity_library',
  ActivityBanner = 'activity_banner',
  ShareEvent = 'share_event',
  communityBanner = 'community_banner',
  communityManiature = 'community_miniature',
  communityLibrary = 'community_library',
  userPost = 'user_post',
  HotspotLibrary = 'hotspot_library',
  HotspotBanner = 'hotspot_banner',
}

export interface SMPacket {
  data: any;
  message: string;
  status: boolean;
}
