<div class="form-label">
  <div class="image"><img [src]="icon" /></div>
  <div class="text-content" [ngClass]="{'visibilityButton': canChangeVisibility}">
    <div class="value">{{value}}</div>
    <div class="name">{{name}}</div>
  </div>
  <div [ngClass]="{'active': updateSuccess}" class="overlay-success">
    Modification enregistré
  </div>
  <div class="edit-button">
    <button [container]="'body'" (click)="changeVisibility()" ngbTooltip="Afficher cette information" class="btn btn-sm" *ngIf="canChangeVisibility && visibilityState == 'me'">
      <img src="/assets/images/hide.svg" />
    </button>
    <button [container]="'body'" (click)="changeVisibility()" ngbTooltip="Cacher cette information" class="btn btn-sm" *ngIf="canChangeVisibility && visibilityState == 'all'">
      <img src="/assets/images/nothide.svg" />
    </button>
    <button [container]="'body'" ngbTooltip="Modifier cette information" (click)="editField = !editField" class="btn btn-sm">
      <img src="/assets/images/settings.png" />
    </button>
  </div>
  <div [ngClass]="{'active': editField}" class="edit-content">
    <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
  </div>
</div>
