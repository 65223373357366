import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MapService } from 'ngx-mapbox-gl';
import { ApiService } from 'src/app/core/services/api.service';
import { AppService } from 'src/app/core/services/app.service';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-user-interface',
  templateUrl: './user-interface.component.html',
  styleUrls: ['./user-interface.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
})
export class UserInterfaceComponent implements OnInit {
  // Services
  public _appService = inject(AppService);
  public _apiService = inject(ApiService);
  public _userService = inject(UserService);
  public _mapService = inject(MapService);
  public _formBuilder = inject(FormBuilder);
  public _changeDetectorRef = inject(ChangeDetectorRef);

  public _personnalForm = signal<FormGroup>(
    this._formBuilder.group({
      langue: ['', [Validators.required]],
    })
  );
  public m_myself = null;
  public langues: any = null;
  public langueSelected: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    private appService: AppService
  ) {
    /*this.m_myself = userService.myself;
    this.langues = appService.GetLangues;*/
  }

  ngOnInit(): void {
    //this.createForm();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this._personnalForm().controls as any;
  }
  /*
  public createForm()
  {
    this.uiForm = this.formBuilder.group({
      langue: ['', [Validators.required]]
    });
  }

  public updateLangue(e)
  {
    if (this.f.langue.valid)
    {
      this.userService.myself.language.uiLang = this.f.langue.value;
      this.userService.SaveAndShareMyself();
    }
  }*/
}
