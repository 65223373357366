import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { App as CapacitorApp } from '@capacitor/app';
import {
  BackgroundColorOptions,
  StatusBar,
  Style,
} from '@capacitor/status-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';
import { LoginFormComponent } from './core/modules/authentification/components/login-form/login-form.component';
import { AuthentificationService } from './core/modules/authentification/services/Authentification.service';
import { NotificationService } from './core/modules/notification/services/notification.service';
import { CustomTranslationService } from './core/modules/translate/translate.service';
import { WebServiceWorker } from './core/services-worker/web-service-worker.service';

SuperTokens.init({
  enableDebugLogs: true,
  appInfo: {
    apiDomain: environment.authApi,
    apiBasePath: '/auth',
    appName: 'socialmove',
  },
  recipeList: [Session.init(), EmailPassword.init()],
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule, RouterModule, LoginFormComponent],
})
export class AppComponent {
  // Services
  private webServiceWorker = inject(WebServiceWorker);
  private notification = inject(NotificationService);
  private _appLang = inject(CustomTranslationService);
  private device = inject(DeviceDetectorService);
  private _auth = inject(AuthentificationService);
  private _route = inject(Router);
  private _dialog = inject(MatDialog);

  // Variables
  isNewAppVersionAvailable: boolean = false;
  newAppUpdateAvailableSubscription: Subscription | null = null;

  constructor() {
    this.initAppLanguage();
    this.initStatusBar();
    this.checkUserConnectionState();
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (this._dialog.openDialogs.length > 0) {
        var dialog = this._dialog.openDialogs.pop();
        dialog?.close();
      } else {
        if (!canGoBack) {
          CapacitorApp.minimizeApp();
        } else {
          window.history.back();
        }
      }
    });
  }

  /**
   * Checks the user's connection state.
   */
  checkUserConnectionState() {
    this._auth.checkConnectionState().then((isConnected) => {
      if (isConnected) {
        //this._route.navigate(['/home']);
      } else {
        this._route.navigate(['/sign']);
      }
    });
  }

  /**
   * Configuration de la langue
   */
  initAppLanguage() {
    this._appLang.setDefaultLocale();
  }

  /**
   * Configuration de la status bar
   */
  initStatusBar() {
    try {
      if (this.device.isMobile()) {
        StatusBar.setOverlaysWebView({ overlay: true });
        StatusBar.setStyle({ style: Style.Default });
        StatusBar.setBackgroundColor(<BackgroundColorOptions>{
          color: 'transparent',
        });
      }
    } catch (e) {}
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.checkIfAppUpdated();
    }, 10000);
  }

  checkIfAppUpdated() {
    this.newAppUpdateAvailableSubscription =
      this.webServiceWorker.$isAnyNewUpdateAvailable.subscribe(
        (versionAvailableFlag: boolean) => {
          this.isNewAppVersionAvailable = versionAvailableFlag;
          this.notification.showLocalPushNotification(
            'Nouvelle version',
            "Nouvelle version de l'application disponible"
          );
        }
      );
  }

  ngOnDestroy() {
    this.newAppUpdateAvailableSubscription?.unsubscribe();
  }
}
