import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StarRatingModule } from 'angular-star-rating';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from 'src/app/core/modules/camera/components/camera/camera.component';
import { PostComponent } from 'src/app/core/modules/post/post.component';
import { UserDetails, Post, Media } from '../../../models/user';

@Component({
  selector: 'app-profile-timeline',
  templateUrl: './profile-timeline.component.html',
  styleUrls: ['./profile-timeline.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    StarRatingModule,
    MatTooltipModule,
    InfiniteScrollModule,
    PostComponent
  ]
})
export class ProfileTimelineComponent implements OnInit {

  public m_profile: UserDetails | undefined;
  public m_friendList: any;
  public m_photoList: any;
  public m_videoList: any;
  public m_defaultPublicUri: any;
  public m_postsList: Post[] = [{
    
  }];
  private pageID: number = 1;
  private pageResult: any = null;
  public fullHeight: boolean = false;

  // Unscriber
  private subscriptions: Subscription[] = [];

  constructor() {
    this.m_defaultPublicUri = environment.fileUrl;
  }

  ngOnInit()
  {

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public changeEventTooltip()
  {
  }

  public openImageViewer(media: any)
  {
    //this.router.navigate(['media', media.ID], {relativeTo: this.activeRoute.parent.parent.parent});
  }

  get friends() {
    return this.m_profile?.friends?.filter((friend: UserDetails, index: number) => index < 9);
  }

  get medias() {
    return this.m_profile?.medias?.filter((media: Media, index: number) => index < 18);
  }

  /**
   * Quand le scrool s'approche du bottom
   * @param e Scroll position currentScrollPosition
   */
  onScrollBottom(e: any)
  {
    if (e == null)
      return;

    /*this.scrollSubject = this.profilePageService.GetAllPostFromOwnerTypeID(this.m_profile.ID, "user", this.pageID).subscribe((response) => {
      if (response.status) {
        this.m_postsList.push(...response.pageResult.data);
        this.pageResult = response.pageResult;
        this.pageID++;
      }
    });*/
  }
}
