import { Injectable, inject, signal } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as en from './../../../../assets/i18n/en.json';
import * as fr from './../../../../assets/i18n/fr-FR.json';

@Injectable()
export class CustomTranslationService {
    
    // Services
    private _translateService = inject(TranslateService)

    // Variables
    public locale = signal("fr-FR");

    constructor() { }

    public setDefaultLocale(): void {
        this.locale.set(navigator.language || 'en');
        this._translateService.setDefaultLang(this.locale());
        this._translateService.use(this.locale());
    }

    public setLocale(userLang: string): void {
        this._translateService.setDefaultLang(userLang);
        this._translateService.use(userLang);
        this.locale.set(userLang);
    }

    public getLocale(): string {
        return this.locale();
    }
}

/**
 * Translater custom pour parse du json et tout autre format
 */
export class CustomTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
      if (lang === 'en') {
        return of(en);
      } else if (lang === 'fr-FR' || lang === 'fr') {
        return of(fr);
      } else {
        return of(en);
      }
    }
  }