import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MapService } from 'ngx-mapbox-gl';
import { ApiService } from 'src/app/core/services/api.service';
import { AppService } from 'src/app/core/services/app.service';
import { MustMatch } from 'src/app/core/utils/tools';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
})
export class AccountComponent implements OnInit {
  // Services
  public _appService = inject(AppService);
  public _apiService = inject(ApiService);
  //public _userService = inject(UserService);
  public _mapService = inject(MapService);
  public _formBuilder = inject(FormBuilder);
  public _changeDetectorRef = inject(ChangeDetectorRef);

  // Variables
  public _accountFormGroup = signal<FormGroup>(
    this._formBuilder.group(
      {
        email: [
          '',
          [Validators.required, Validators.email, Validators.minLength(6)],
        ],
        confirmEmail: [
          '',
          [Validators.required, Validators.email, Validators.minLength(6)],
        ],
        password: ['', [Validators.minLength(6)]],
        confirmPassword: ['', [Validators.minLength(6)]],
        emailDeleteAccount: [
          '',
          [Validators.required, Validators.email, Validators.minLength(6)],
        ],
      },
      {
        validator: [
          MustMatch('password', 'confirmPassword'),
          MustMatch('email', 'confirmEmail'),
        ],
      }
    )
  );
  public addressError: any;
  public userAddress: string = '';

  constructor() {
    //this.m_myself = _userService.myself;
  }

  ngOnInit(): void {}

  // convenience getter for easy access to form fields
  get f() {
    return this._accountFormGroup()?.controls as any;
  }

  /**
   * Envoi un mail avec les instruction pour la modification
   */
  public updateEmail(newMail: string) {
    /*this.userService.changeUserMailRequest(this._accountFormGroup.controls.confirmEmail.value).subscribe((response) => {
      if (response.status) {
        alert('Email envoyé avec les instruction');
      } else {
        alert('Erreur pendant la requete : ' + response.error);
      }
    });*/
  }

  /**
   * Password
   */
  public updatePassword() {
    /*this.userService
      .changeUserPasswordRequest(this._accountFormGroup.controls.confirmPassword.value)
      .subscribe((response) => {
        if (response.status) {
          alert('Email envoyé avec les instruction');
        } else {
          alert('Erreur pendant la requete : ' + response.error);
        }
      });*/
  }

  /**
   * Password
   */
  public deleteAccount() {
    /*this.userService.deleteAccountRequest().subscribe((response) => {
      if (response.status) {
        alert('Email envoyé avec les instruction');
      } else {
        alert('Erreur pendant la requete : ' + response.error);
      }
    });*/
  }
}
