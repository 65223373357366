import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ProfileTimelineComponent } from './app/features/user/components/profile/profile-timeline/profile-timeline.component';
import { UserSettingsComponent } from './app/features/user/components/settings/user-settings.component';
import { AccountComponent } from './app/features/user/components/settings/views/account/account.component';
import { ConfidentialityComponent } from './app/features/user/components/settings/views/confidentiality/confidentiality.component';
import { NotificationComponent } from './app/features/user/components/settings/views/notification/notification.component';
import { PersonnalComponent } from './app/features/user/components/settings/views/personnal/personnal.component';
import { UserInterfaceComponent } from './app/features/user/components/settings/views/user-interface/user-interface.component';
import { IsConnected } from './app/core/modules/authentification/guards/authentification.guard';

export const APP_ROUTES: Routes = [
    { path: '', redirectTo: 'sign', pathMatch: 'full' },
    /**
     * Route /sign
     */
    {
      path: 'sign',
      loadComponent: () =>
        import(
          './app/features/authentification/components/sign-in-up/sign-in-up.component'
        ).then((m) => m.SignInUpComponent),
        children: [
          {
            path: '',
            redirectTo: 'in',
            pathMatch: 'full'
          },
          {
            path: 'in',
            loadComponent: () =>
              import('./app/core/modules/authentification/components/login-form/login-form.component').then(
                (m) => m.LoginFormComponent
              ),
          },
          {
            path: 'up',
            loadComponent: () =>
            import('./app/core/modules/authentification/components/register-form/register-form.component').then(
              (m) => m.RegisterFormComponent
            ),
          },
        ]
    },
    /**
     * Route /home
     */
    {
      path: 'home',
      canActivate: [IsConnected],
      loadComponent: () =>
        import('./app/features/home/home.component').then((m) => m.HomeComponent),
      children: [
        {
          path: '',
          loadComponent: () =>
            import(
              './app/core/modules/map/components/mapCanvas/mapCanvas.component'
            ).then((m) => m.MapCanvasComponent),
          providers: [
            importProvidersFrom(
              NgxMapboxGLModule.withConfig({
                accessToken:
                  'pk.eyJ1IjoibWluZW9zIiwiYSI6ImNqa2lrMWh6aTEybnAzdm16M2Z3NjR2cmsifQ.SkmgyPynoUq78N-g1-pcAA', // Optional, can also be set per map (accessToken input of mgl-map)
              })
            ),
          ],
        },
        /**
         * Route /hotspots
         */
        {
          path: 'hotspot',
          children: [
            { path: '', redirectTo: 'create', pathMatch: 'full' },
            {
              path: 'create',
              loadComponent: () =>
                import('./app/features/hot-spot/components/item/item.component').then(
                  (m) => m.ItemComponent
                ),
            },
            {
              path: 'edit:id',
              loadComponent: () =>
                import('./app/features/hot-spot/components/item/item.component').then(
                  (m) => m.ItemComponent
                ),
            },
          ],
        },
        /**
         * Route /user
         */
        {
          path: 'user',
          children: [
            {
              path: 'demo',
              loadComponent: () =>
                import(
                  './app/features/user/components/profile/profile-page.component'
                ).then((m) => m.ProfilePageComponent),
              children: [
                { path: '', redirectTo: 'timeline', pathMatch: 'full' },
                { path: 'timeline', component: ProfileTimelineComponent },
              ],
            },
            {
              path: 'settings',
              component: UserSettingsComponent,
              children: [
                { path: '', redirectTo: 'personnal', pathMatch: 'full' },
                { path: 'personnal', component: PersonnalComponent },
                { path: 'ui', component: UserInterfaceComponent },
                { path: 'notification', component: NotificationComponent },
                { path: 'confidentiality', component: ConfidentialityComponent },
                { path: 'account', component: AccountComponent },
              ],
            },
          ],
        },
        /**
         * Routed modal
         */
        {
          path: 'camera',
          loadComponent: () =>
            import(
              './app/core/modules/camera/components/camera/camera.component'
            ).then((m) => m.CameraComponent),
        },
      ],
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
  ];