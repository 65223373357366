import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StarRatingModule } from 'angular-star-rating';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from '../../modules/camera/components/camera/camera.component';
import { PostComponent } from '../../modules/post/post.component';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    StarRatingModule,
    MatTooltipModule,
    InfiniteScrollModule,
    PostComponent,
    NgbTooltipModule,
  ],
})
export class EditFieldComponent implements OnInit {
  @Output('success') onSuccess = new EventEmitter<string>();
  @Output('onVisibilityChange') onVisibilityChange = new EventEmitter<string>();

  @Input()
  public value: string = '';
  @Input({ required: true })
  public icon: string = '';
  @Input({ required: true })
  public name: string = '';
  @Input({ required: true })
  public editTemplate: TemplateRef<any> | null = null;
  @Input()
  public canChangeVisibility: boolean = false;
  @Input()
  public visibilityState = 'all';

  public editField: boolean = false;
  public currentTemplate: TemplateRef<any> | null = null;
  public updateSuccess: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.currentTemplate = this.editTemplate;
  }

  success(): void {
    this.editField = false;
    this.onSuccess.emit(this.value);
    this.updateSuccess = true;
    setTimeout(() => {
      this.updateSuccess = false;
    }, 10000);
  }

  /**
   * Change la visibilité de cette propriété, entre me et all
   */
  changeVisibility() {
    switch (this.visibilityState) {
      case 'all':
        this.visibilityState = 'me';
        break;
      case 'me':
        this.visibilityState = 'all';
        break;
      default:
        this.visibilityState = 'me';
        break;
    }
    this.onVisibilityChange.emit(this.visibilityState);
  }

  close(): void {
    this.editField = false;
  }
}
