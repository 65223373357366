import { inject } from '@angular/core';
import { Router } from '@angular/router';
import Session from 'supertokens-web-js/recipe/session';

export async function IsConnected() {
  var router = inject(Router);
  if (!await Session.doesSessionExist())
  {
    router.navigate(["/sign"]);
    return false;
  }
  else
    return true;
}
