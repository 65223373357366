<div class="content_answer" *ngIf="comment.user != undefined">
  <div class="comment_block">
    <div class="header" style="padding-top: 5px;">
      <div class="comment_picture">
        <img src="{{comment.user.profileImage | publicData}}" width="30px" height="30px" />
        </div>
        <div class="comment_name">
          <p><b><user-label [user]="comment.user"></user-label></b></p>
        </div>
        <div class="comment_created_at">
          <p>{{comment.CreatedAt}}</p>
        </div>
      </div>
      <div class="content">
        <p>{{comment.text}}</p>
        <div class="comment_user_reaction">
          <ul>
            <li>
              <div class="heart_icon_min" (click)="updateLike()" [ngClass]="{'active': iLike}" title="Coup de coeur"></div>
              <div class="like_counter" style="margin-top: -2px;"><b>{{likeCounter}}</b></div>
            </li>
          </ul>
          <!--<ul class="share_comment_icons">
            <li><div class="button_message_icon_min" title="Afficher les commentaires"></div></li>
            <li><div class="button_share_icon_min" title="Partager"></div></li>
          </ul>-->
        </div>
      </div>
      <div *ngIf="isLastComment" class="line_spliter_white"></div>
  </div>
</div>
