/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_PROVIDERS } from './providers';
import { provideRouter, withDebugTracing } from '@angular/router';
import { APP_ROUTES } from './routes';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'hammerjs';

defineCustomElements(window);
// Start standalone angular app
const appref = bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES, withDebugTracing()),
    APP_PROVIDERS],
});