import { Injectable, inject } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/services/api.service';
import { UserDetails } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ProfilePageService {

  // Services
  private apiService = inject(ApiService);
  private http = inject(HttpClient);


  public profilePageSubject = new BehaviorSubject<any>(null);
  public profilePageUpdateFieldSubject = new BehaviorSubject<any>(null);
  public profilePageScrolling = new BehaviorSubject<any>(null);
  public profilePageLoadMoreItem = new BehaviorSubject<any>(null);
  public m_profilePage: any;

  constructor() {
    /*this.socket.onFriendListChangeSubject.subscribe(() => {
      this.emitProfilePageUpdateField();
    });*/
  }

  /**
   * Propagation de la page profil apres une mise a jour
   */
  public emitProfilePageSubject() : void {
    this.profilePageSubject.next(this.m_profilePage);
  }

  public emitProfilePageScrolling(scrolling: boolean): void {
    this.profilePageScrolling.next(scrolling);
  }

  public emitProfilePageLoadMoreItem(scrolling: any): void {
    this.profilePageLoadMoreItem.next(scrolling);
  }

  public emitProfilePageUpdateField(): void {
    this.profilePageUpdateFieldSubject.next(null);
  }

  /**
   * Charge les données d'une page de profile à partir de l'id de l'user
   * @param userId User id
   */
  public LoadProfilePage(userId: number) : Observable<UserDetails>
  {
    return new Observable(observer => {
      this.apiService.getProfilePageById(userId).subscribe((request) => {
        observer.next(request.data);
        this.m_profilePage = request.data;
        this.emitProfilePageSubject();
        observer.complete();
      });
    });
  }

  /**
   * Sauvegarde un media (fichier+nom+date+type+etc)
   * @param media Media to save
   */
  public saveMedia(media: any) : Observable<any>
  {
    return this.http.post<any>(environment.apiUrl + "/user/media/set/", media);
  }

  public createPost(post: any)
  {
    return this.http.post<any>(environment.apiUrl + "/profile/post/create/", post);
  }

  public deletePost(post: any) {
    return this.http.post<any>(environment.apiUrl + "/profile/post/delete/", post);
  }

  public createComment(comment: any)
  {
    return this.http.post<any>(environment.apiUrl + "/profile/post/comment/create/", comment);
  }

  /**
   * Get all media of user
   * @returns Array of media
   */
  public getAllUserMedia(userId: number)
  {
    return this.http.get<any>(environment.apiUrl + "/user/media/get/all/?user_id=" + userId);
  }

  /**
   * Recupere les post d'un @ownerType à partir de son ID @OwnerId
   * @param ownerId Profile Id
   * @param ownerType Type ("event", "user", "activity")
   */
     public GetAllPostFromOwnerTypeID(ownerId: number, ownerType: string, pageNumber: number = 0)
     {
       return this.http.get<any>(environment.apiUrl  + "/post/get/?owner_id=" + ownerId + "&owner_type=" + ownerType + "&page=" + pageNumber);
     }
}
