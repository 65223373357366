<div id="userSetting">
  <div class="menu vertical">
    <h3>Paramètres</h3>
    <ul>
      <li [routerLinkActive]="['active']" routerLink="personnal">
        <img src="/assets/images/account.svg" />
        <div class="menu-text">Information personnel</div>
      </li>
      <li [routerLinkActive]="['active']" routerLink="ui">
        <img src="/assets/images/layout.svg" />
        <div class="menu-text">Interface</div>
      </li>
      <li [routerLinkActive]="['active']" routerLink="notification">
        <img src="/assets/images/notification.svg" />
        <div class="menu-text">Notification</div>
      </li>
      <li [routerLinkActive]="['active']" routerLink="confidentiality">
        <img src="/assets/images/confidentiality.svg" />
        <div class="menu-text">Confidentialité</div>
      </li>
      <li [routerLinkActive]="['active']" routerLink="account">
        <img src="/assets/images/settings.svg" />
        <div class="menu-text">Compte</div>
      </li>
    </ul>
  </div>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>
