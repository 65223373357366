<div class="post" *ngIf="post != null">
  <app-header-user (onEditText)="editText($event)" [post]="post"></app-header-user>
  <div class="content">
    <!-- Text content -->
    <p class="content_text" *ngIf="!m_editText" [innerHtml]="post.text"></p>
    <!-- Edite poste -->
    <div *ngIf="m_editText">
      <textarea id="write_post_textarea" [(ngModel)]="post.text" *ngIf="m_editText"></textarea>
      <div *ngIf="m_editText" id="edit_post_button">
        <button class="btn btn-sm btn-primary" (click)="updatePost()">Enregister les modification</button>
        <button style="margin-left: 10px;" class="btn btn-sm btn-danger" (click)="m_editText = false">Annuler</button>
      </div>
    </div>
    <!-- Event post -->
    <div [routerLink]="['/home/event/', post?.event?.ID]" class="media" *ngIf="post.event && post.medias != null && post.medias.length > 0 && showMedia">
      <img [src]="post.medias[0].data | publicData" />
      <div  class="title">
        <h5><b>{{post?.event?.name}}</b></h5>
      </div>
      <div class="start_date">
        <div class="month">{{post.event.startDate | date: "MMMM"}}</div>
        <div class="day">{{post.event.startDate | date: "dd"}}</div>
      </div>
      <div class="media_overlay_event">
        <ul>
          <li><span [innerHtml]="post?.event?.description"></span></li>
        </ul>
      </div>
    </div>
    <!-- Media post -->
    <div class="media" *ngIf="!post.event && post.medias != null && post.medias.length > 0 && showMedia">
      <div class="imagePost" *ngFor="let media of post.medias">
        <img [routerLink]="['',{ outlets: { modal: ['popupviewer', media.ID]}}]" [src]="media.data | publicData" />
      </div>
    </div>

    <div class="line_spliter"></div>
    <div class="user_reaction">
      <ul>
        <li>
          <div class="heart_icon" (click)="updateLike()" [ngClass]="{'active': iLike}" title="Coup de coeur"></div>
          <div class="like_counter"><b>{{likeCounter}}</b></div>
        </li>
        <li *ngIf="post.commentCount != null">
          <div class="comment_icon" title="Nombre de commentaire"></div>
          <div class="like_counter"><b>{{post.commentCount}}</b></div>
        </li>
        <li *ngIf="post.likes != null" style="margin-left: 10px">
          <ul class="like_picture">
            <span *ngFor="let like of post.likes; let i=index" [ngStyle]="{'margin-left': i == post.likes.length ? '0px' : '-10px','display': i >= likePictureSize ? 'none' : 'inline-block'}">
            <li *ngIf="i < likePictureSize && like.state == 'like' && like.user != undefined" [ngStyle]="{'display': i >= likePictureSize ? 'none' : 'inline-block'}">
              <img [title]="like.user.firstname + ' ' + like.user.lastname" routerLink="/home/user/{{like.user.ID}}" src="{{like?.user?.profileImage | publicData}}" width="25px" height="25px" />
            </li>
            </span>
          </ul>
        </li>
        <li *ngIf="post.likes != null" class="postLikeText" style="padding-left: 10px;">
          <span *ngFor="let like of post.likes; let i=index">
            <div class="like_names_inline" *ngIf="i < likeNameSize && like.state == 'like' && like.user != undefined">
            <p routerLink="/home/user/{{like.user.ID}}/timeline"  class="text_liked_by_friend"><user-label [user]="like.user"></user-label><span *ngIf="post.likes.length > 1 && i < (likeNameSize - 1)">,&nbsp;</span></p>
          </div>
          </span>
          <p *ngIf="post.likes.length > likeNameSize" class="text_liked_by_friend"> et {{post.likes.length - likeNameSize}} autre personne(s)</p>
        </li>
      </ul>
      <ul class="share_comment_icons">
        <li *ngIf="canAddComment"><div (click)="openComment()" class="button_message_icon" title="Ecrire un commentaire"></div></li>
        <!--<li><div class="button_share_icon" title="Partager"></div></li>-->
      </ul>
    </div>
  </div>
  <app-write-comment (onCommentCreate)="onCommentCreate($event)" [ownerType]="ownerType" [id]="post.ID" [showCreateComment]="showCreateComment"></app-write-comment>
  <div class="comments_list">
    <div *ngIf="post.commentCount != null && post.commentCount > 0 && commentsPageResult && commentsPageResult.page && commentsPageResult.total_records && commentsPageResult.page_size && commentsPageResult.total_records == 0 && commentsList?.length != null && commentsList.length <= 0" class="loadMoreComments" (click)="loadComments()">
      <p><img src="/assets/images/curve-down-arrow-left.svg" width="15px" height="15px" />Afficher les commentaires<img src="/assets/images/curve-down-arrow-right.svg" width="15px" height="15px" /></p>
    </div>
    <app-comment [comment]="comment" *ngFor="let comment of commentsList"></app-comment>
    <div *ngIf="!commentsIsLoaded" class="commentsLoader">
      <p><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Chargement</p>
    </div>
    <div *ngIf="commentsList && commentsPageResult && commentsPageResult.page && commentsPageResult.total_records && commentsPageResult.page_size && (commentsPageResult.page) <= (commentsPageResult.total_records / commentsPageResult.page_size)" class="loadMoreComments" (click)="loadComments()">
      <p><img src="/assets/images/curve-down-arrow-left.svg" width="15px" height="15px" />Afficher plus commentaires<img src="/assets/images/curve-down-arrow-right.svg" width="15px" height="15px" /></p>
    </div>
  </div>
</div>
