import { FacebookLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { isDevMode, importProvidersFrom } from "@angular/core";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ServiceWorkerModule, provideServiceWorker } from "@angular/service-worker";
import { provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { StarRatingModule } from "angular-star-rating";
import { TimeagoModule, TimeagoPipe } from "ngx-timeago";
import { provideToastr } from "ngx-toastr";
import { WebcamModule } from "ngx-webcam";
import { AppService } from "./services/app.service";
import { headerInterceptor } from "./app/core/interceptors/header-interceptor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CustomTranslateLoader, CustomTranslationService } from "./app/core/modules/translate/translate.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { EVENT_MANAGER_PLUGINS } from "@angular/platform-browser";

export const APP_PROVIDERS = [
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideAnimations(),
    importProvidersFrom(
      TimeagoModule.forRoot(), 
      TimeagoPipe, 
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader
        }
      }),
      MatSnackBarModule,
      HttpClientModule,
      WebcamModule,
      StarRatingModule.forRoot(),
      MatDialogModule,
      MatNativeDateModule,
      MatDatepickerModule
    ),
    CustomTranslationService,
    provideToastr(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1456666915269952'),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AppService,
    provideStore(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([headerInterceptor])
    )
  ];