import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GpsPosition } from 'src/app/core/modules/map/services/map.service';
import { environment } from 'src/environments/environment';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors?.['mustMatch']) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
 * Creates a GeoJSON polygon representing a circle on the earth.
 *
 * @param {Object} center - An object with `latitude` and `longitude` properties
 * @param {number} radiusInKm - The radius of the circle in kilometers
 * @param {number} [points=64] - The number of points used to approximate the circle
 * @returns {Object} A GeoJSON object representing the circle
 */
export function createGeoJSONCircle(
  latitude: number,
  longitude: number,
  radiusInKm: number,
  points = 64
): any {
  const coords = {
    latitude: latitude,
    longitude: longitude,
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta, x, y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret],
          },
        },
      ],
    },
  };
}

@Injectable()
export class UtilityService {
  UID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
}

@Pipe({
  name: 'publicData',
  standalone: true,
})
export class PublicDataPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';

    if (value.indexOf('http') == -1) {
      return environment.fileUrl + value;
    } else {
      return value;
    }
  }

  checkImage(imageSrc: any, good: any, bad: any) {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }
}

/**
 * Récuperation de la position GPS de l'utilisateur
 * @returns Observable<GpsPosition | null>
 */
export function getLocationAsync(
  success: (position: GpsPosition) => void,
  error?: (err: string) => void
): void {
  try {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { longitude, latitude } = position.coords;
        success({ longitude: longitude, latitude: latitude });
      });
    } else {
      console.log('No support for geolocation');
      if (error) error('No support for geolocation');
    }
  } catch (e: any) {
    if (error) error(e.toString());
  }
}

export function getBase64(file: any, cbsuccess: any, cberror: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cbsuccess({ result: reader.result, file: file });
  };
  reader.onerror = function (error) {
    cberror(error);
  };
}
