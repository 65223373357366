import { Component, OnInit, inject, Input } from "@angular/core";
import { NgbPopover, NgbPopoverModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/core/services/api.service";
import { PublicDataPipe, UtilityService } from "src/app/core/utils/tools";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { WebcamModule } from "ngx-webcam";
import { UserDetails } from "src/app/features/user/models/user";
import { CameraComponent } from "../../modules/camera/components/camera/camera.component";


@Component({
  selector: 'user-label',
  templateUrl: './user-label.component.html',
  styleUrls: ['./user-label.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    NgbPopoverModule,
    NgbTooltipModule
  ]
})
export class UserLabelComponent implements OnInit {

  // Services
  private userService = inject(ApiService);

  @Input("user")
  public m_user?: UserDetails;
  public onDelayToCloseAttempt?: boolean;
  public isOnHover:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public changePopupState(popup: NgbPopover, state: boolean) {
    if (state) {
      this.isOnHover = true;
      setTimeout(() => {
        if (this.isOnHover)
        {
           popup.open();
        }
      }, 300);
    } else if (!state && this.onDelayToCloseAttempt) {
      this.isOnHover = false;
      popup.close();
    }
  }

  public changePopupOnHover(popup: NgbPopover, state: boolean) {
    this.isOnHover = state;

    if (!state) {
      this.poupDelayClose(popup);
    }
  }

  public poupDelayClose(popup: NgbPopover, force: boolean = false)
  {
    setTimeout(() => {
      this.onDelayToCloseAttempt = true;
      if (!this.isOnHover || force)
        popup.close();
    }, 300);
  }

  public closeDelay(popup: NgbPopover)
  {
    setTimeout(() => {
      this.isOnHover = false;
      this.onDelayToCloseAttempt = true;
      popup.close();
    }, 300);
  }

  loadProfile(userId: number) {
    this.userService.getUserById(userId).subscribe((response) => {
      if (response.status) {
        this.m_user = response.data;
      }
    }).unsubscribe();
  }
}
