import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';
import { HttpResponse } from '../../http/response';
import { User } from 'supertokens-web-js/lib/build/types';
import { UserDetails } from 'src/app/features/user/models/user';
import { UserService } from 'src/app/features/user/services/user.service';
import { NotificationService } from '../../notification/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  // Services
  private _socialAuth = inject(SocialAuthService);
  private _httpService = inject(HttpClient);
  private _userService = inject(UserService);
  private _notif = inject(NotificationService);
  private _translate = inject(TranslateService);
  
  // Events
  public _connectionState = new BehaviorSubject<User | null>(null);

  // Variables
  private _isGuest = signal(false);
  private _user: WritableSignal<User | null> = signal(null);
  private _isConnected = signal(false);
  private _token = '';

  private fbLoginOptions = {
    scope: FacebookConfiguration.SCOPE,
    return_scopes: FacebookConfiguration.RETURN_SCOPES,
    enable_profile_selector: FacebookConfiguration.ENABLE_PROFILE_SELECTOR,
    version: FacebookConfiguration.VERSION,
  };
  private googleLoginOptions = {
    scope: FacebookConfiguration.SCOPE,
    return_scopes: FacebookConfiguration.RETURN_SCOPES,
    enable_profile_selector: FacebookConfiguration.ENABLE_PROFILE_SELECTOR,
    version: FacebookConfiguration.VERSION,
  };

  /**
   * Checks si l'userestun invité
   */
  public get isGuest(): boolean {
    return this._isGuest();
  }

  /**
   * Recupere l'instance de l'user
   */
  public get user(): User | null {
    return this._user();
  }



  /**
   * Disconnect user
   */
  public async disconnect() {
    await Session.signOut();
  }

  /**
   * Récupere les informations utilisateur à la premiere connection
   * @returns
   */
  public async startSocialMoveConnection() {
    return await this._userService.GetME();
  }

  /**
   * Tentative de connexion à facebook
   */
  startConnectionFacebook() {
    this._socialAuth
      .signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions)
      .then((u) => {
        Session.doesSessionExist().then((session) => {
          console.dir(session);
        });
        this._token = u.authToken;
        this._isConnected.set(true);
      })
      .catch((err) => {
        this._isConnected.set(false);
      });
  }

  /**
   * Tentative de connexion à Supertokens
   */
  startConnection(email: string, password: string): void {
    EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email,
        },
        {
          id: 'password',
          value: password,
        },
      ],
    })
      .then((response) => {
        if (response.status === 'OK') {
          this._user.set(response.user);
          this._isConnected.set(true);
          this._connectionState.next(response.user);
        } else {
          this._isConnected.set(false);
          this._connectionState.next(null);
          this._notif.simplyNotification(this._translate.instant("AUTHENTIFICATION.label_cant_connect"), "ok");
        }
      })
      .catch((e) => {
        this._isConnected.set(false);
        this._notif.simplyNotification(e.toString(), "ok");
      });
  }

  /**
   * Verifie si l'user est connecté à l'api
   */
  public async checkConnectionState() {
    return await Session.doesSessionExist();
  }
}

/**
 * Variables statique de config FaceBook OAUTH
 */
export class FacebookConfiguration {
  public static SCOPE = 'public_profile';
  public static RETURN_SCOPES = true;
  public static ENABLE_PROFILE_SELECTOR = true;
  public static VERSION = 'v18.0';
}

/**
 * Variables statique de config Google OAUTH
 */
export class GoogleConfiguration {
  public static SCOPE = 'public_profile';
  public static RETURN_SCOPES = true;
  public static ENABLE_PROFILE_SELECTOR = true;
  public static VERSION = 'v18.0';
}
