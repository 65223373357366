import {
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const headerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  let header = req.clone({
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': environment.production
        ? 'https://app.socialmove.com'
        : 'http://192.168.1.150:4200',
      'Access-Control-Allow-Credentials': 'true',
    }),
  });

  return next(header);
};
