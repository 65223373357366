import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CommentSearchRequest, ChatMessage, Follow, Bookmark, UserDetails } from 'src/app/features/user/models/user';
//import { INotification } from '../class/notification';

const API_URL = environment.apiUrl;
interface message {
  text: string;
  fromUserId: number;
  toGroupId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // Services
  private http: HttpClient = inject(HttpClient);

  // Variables

  constructor() {
  }
    
  /**
   * Supprime une media
   * @param media 
   * @returns 
   */
  public deleteMedia(media: any) {
    return this.http.post<any>(environment.apiUrl + '/media/delete/', media);
  }

  /**
   * Récupere la liste de message d'un groupe
   * @param groupId
   * @param pageNumber
   */
  public getGroupMessagesFromGroupId(groupId: number, pageNumber: number) {
    return this.http.get<any>(
      API_URL +
      '/group/message/get/?group_id=' +
      groupId +
      '&page=' +
      pageNumber
    );
  }

  /**
   * Créer un message de groupe
   * @param message
   */
  public CreateGroupMessage(message: message) {
    return this.http.post<any>(API_URL + '/group/message/create/', message);
  }

  /**
   * Permet de mettre à jour le poste d'un utilisateur
   * @param post Poste à mettre à jour
   */
  public UpdatePost(post: any) {
    return this.http.post<any>(API_URL + '/post/update/', post);
  }

  /**
   * Verifie la validité d'un token pour changer un mot de passe et email
   * @param token Token receive from mail
   * @param type mail/password
   */
  public CheckTokenIsValid(token: string, type: string) {
    return this.http.get<any>(
      API_URL + '/check/token/?token=' + token + '&type=' + type
    );
  }

  /**
   * Recupere les post d'un user
   * @param ownerId Profile Id
   * @param ownerType
   */
  public GetUserMediaDetailFromId(mediaId: number) {
    return this.http.get<any>(
      API_URL + '/user/media/get/?media_id=' + mediaId);
  }

  /**
   * Recupere les post d'un user
   * @param ownerId Profile Id
   * @param ownerType
   */
  public GetEventMediaDetailFromId(mediaId: number) {
    return this.http.get<any>(
      API_URL + '/event/media/get/?media_id=' + mediaId);
  }

  public HasPendingFriendRequest(userId: number) {
    return this.http.get<any>(
      environment.apiUrl + '/friend/request/pending/?user_id=' + userId
    );
  }

  /**
   *
   * @param ownerId
   * @param ownerType
   */
  public GetAllUserPostFromOwnerTypeID(ownerId: number, ownerType: string) {
    return this.http.get<any>(
      API_URL +
      '/post/user/get/?owner_id=' +
      ownerId +
      '&owner_type=' +
      ownerType
    );
  }

  /**
   * Permet d'inviter un user dans un groupe
   * @param toUserId Id de la personne à inviter
   * @param fromUserId Id demandeur
   * @param groupId Id du groupe cible
   */
  public InviteFriendToGroup(
    toUserId: number,
    groupId: number
  ) {
    return this.http.post<any>(API_URL + '/group/invite/friend/', {
      toUserId: toUserId,
      type: 'inviteGroup',
      dataRelation: groupId.toString(),
    });
  }

  /**
   * Permet d'inviter un user dans un groupe
   * @param toUserId Id de la personne à inviter
   * @param fromUserId Id demandeur
   * @param groupId Id du groupe cible
   */
  public InviteFriendToCommunity(
    toUserId: number,
    fromUserId: number,
    communityId: number
  ) {
    return this.http.post<any>(API_URL + '/community/invite/friend/', {
      toUserId: toUserId,
      fromUserId: fromUserId,
      type: 'inviteCommunity',
      dataRelation: communityId.toString(),
    });
  }

  /**
   * Permet d'inviter un user dans un groupe
   * @param toUserId Id de la personne à inviter
   * @param fromUserId Id demandeur
   * @param groupId Id du groupe cible
   */
  public InviteFriendToEvent(
    toUserId: number,
    fromUserId: number,
    eventId: number
  ) {
    return this.http.post<any>(API_URL + '/event/invite/friend/', {
      toUserId: toUserId,
      fromUserId: fromUserId,
      type: 'inviteEvent',
      dataRelation: eventId.toString(),
    });
  }

  /**
   * Permet d'inviter un user dans un groupe
   * @param toUserId Id de la personne à inviter
   * @param fromUserId Id demandeur
   * @param groupId Id du groupe cible
   */
  public InviteFriendToActivity(
    toUserId: number,
    fromUserId: number,
    activityId: number
  ) {
    return this.http.post<any>(API_URL + '/activity/invite/friend/', {
      toUserId: toUserId,
      fromUserId: fromUserId,
      type: 'invite_activity',
      dataRelation: activityId.toString(),
    });
  }

  /**
   * Accepte un invitation en groupe
   * @param notif Notification a retourner
   */
  public AccepteInvitationToGroup(notif: any) {
    return this.http.post<any>(
      API_URL + '/group/invite/friend/response/',
      notif
    );
  }

  /**
   * Accepte un invitation en groupe
   * @param notif Notification a retourner
   */
  public AccepteInvitationToCommunity(notif: any) {
    return this.http.post<any>(
      API_URL + '/community/invite/friend/response/',
      notif
    );
  }

  /**
   * Accepte un invitation en groupe
   * @param notif Notification a retourner
   */
  public AccepteInvitationToActivity(notif: any) {
    return this.http.post<any>(
      API_URL + '/activity/invite/friend/response/',
      notif
    );
  }

  /**
   * Ajoute une note à un event
   * @param userId User id
   * @param eventId Event Id
   * @param note Note
   */
  public AddRatingGroup(data: any) {
    return this.http.post<any>(API_URL + '/group/rating/add/', data);
  }

  /**
   * Ajoute une note à un event
   * @param userId User id
   * @param eventId Event Id
   * @param note Note
   */
  public AddRatingEvent(data: any) {
    return this.http.post<any>(API_URL + '/event/rating/add/', data);
  }

  /**
   * Ajoute une note à un event
   * @param userId User id
   * @param eventId Event Id
   * @param note Note
   */
  public AddRatingActivity(data: any) {
    return this.http.post<any>(API_URL + '/activity/rating/add/', data);
  }

  /**
   * Créer une charge stripe
   * @param userId id of user
   */
  public createSubscriptionPremium(customerId: string) {
    return this.http.post<any>(API_URL + '/stripe/subscription/create/', {
      customerId: customerId,
    });
  }

  /**
   * Créer une charge stripe
   * @param userId id of user
   */
  public cancelSubscriptionPremium(customerId: string) {
    return this.http.post<any>(API_URL + '/stripe/subscription/cancel/', {
      customerId: customerId,
    });
  }

  /**
   * Recupere la liste complete des filtre utilisé pour les event
   */
  public getFiltersMap(): Observable<any> {
    return this.http.get<any>(API_URL + '/filter/get/all/');
  }

  /**
   * Recupere la liste complete des filtre utilisé pour les event
   */
  public getSubFiltersMap(): Observable<any> {
    return this.http.get<any>(API_URL + '/filter/sub/get/all/');
  }

  /**
   * Recupere la liste complete des filtre utilisé pour les event
   */
  public getCommentsFromPostId(
    postId: number,
    pageNumber = 0,
    pagesize = 12,
    order = 'DESC'
  ): Observable<CommentSearchRequest> {
    return this.http.get<CommentSearchRequest>(
      API_URL +
      '/post/comment/get/?post_id=' +
      postId +
      '&page=' +
      pageNumber +
      '&pagesize=' +
      pagesize +
      '&order=' +
      order
    );
  }

  /**
   * Recupere la liste complete des filtre utilisé pour les event
   */
  public getFilter(filterId: number): Observable<any> {
    return this.http.get<any>(API_URL + '/filter/get/?filter_id=' + filterId);
  }

  /**
   * Recupere la liste complete des communautés
   */
  public getCommunities(
    page: number,
    pagesize = 12,
    searchRequest: any
  ): Observable<any> {
    const rq = new URLSearchParams(searchRequest).toString();
    return this.http.get<any>(
      API_URL +
      '/community/get/all/?page=' +
      page +
      '&pagesize=' +
      pagesize +
      '&' +
      rq
    );
  }

  /**
   * Recupere la liste complete des communautés
   */
  public getMyCommunities(): Observable<any> {
    return this.http.get<any>(API_URL + '/community/get/my/all/');
  }

  /**
   * Recupere les informations meteorologique depuis la latitude/longitude
   * @param lon Longitude
   * @param lat Latitude
   */
  public GetWeatherFromCoordinate(lon: any, lat: any) {
    return this.http.get<any>(
      'https://api.openweathermap.org/data/2.5/weather?lat=' +
      lat +
      '&lon=' +
      lon +
      '&appid=597602319af7187f32658007858f92c4',
      {
        headers: new HttpHeaders({
          ignore_interceptor: '',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      }
    );
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public getUserById(userId: number) {
    return this.http.get<any>(API_URL + '/friend/?userId=' + userId);
  }

  /**
   * Recupere les informations d'une adresse
   * @param address Adresse à autocompleter
   */
  public getAutoCompleteAddress(address: string) {
    return this.http.get<any>(
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      address +
      '.json?limit=5&access_token=pk.eyJ1IjoibWluZW9zIiwiYSI6ImNqa2lrMWh6aTEybnAzdm16M2Z3NjR2cmsifQ.SkmgyPynoUq78N-g1-pcAA'
    );
    //return this.http.get<any>("https://api-adresse.data.gouv.fr/search/?q=" + address, {headers: new HttpHeaders({"ignore_interceptor": "", 'Content-Type': 'application/json', 'Accept': 'application/json'})});
  }

  /**
   * Recupere les informations depuis des coordonné
   * @param lon Adresse à autocompleter
   * * @param lat Adresse à autocompleter
   */
  public getGeocodingFromCoordinate(lon: string, lat: string) {
    return this.http.get<any>(
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      lon + ',' + lat +
      '.json?access_token=pk.eyJ1IjoibWluZW9zIiwiYSI6ImNqa2lrMWh6aTEybnAzdm16M2Z3NjR2cmsifQ.SkmgyPynoUq78N-g1-pcAA'
    );
    //return this.http.get<any>("https://api-adresse.data.gouv.fr/search/?q=" + address, {headers: new HttpHeaders({"ignore_interceptor": "", 'Content-Type': 'application/json', 'Accept': 'application/json'})});
  }

  /**
   * Recupere les informations d'une adresse
   * @param address Adresse à autocompleter
   */
  public getReverseAutoCompleteAddress(lon: string, lat: string) {
    return this.http.get<any>(
      'https://api-adresse.data.gouv.fr/reverse/?lon=' + lon + '&lat=' + lat,
      {
        headers: new HttpHeaders({
          ignore_interceptor: '',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      }
    );
  }

  /**
   * Get all friend chat message from friend id
   * @param friendId event id
   */
  public GetFriendChatMessagesFromId(friendId: number, pageId = 0) {
    return this.http.get<any>(
      API_URL + '/friend/message/get/?friend_id=' + friendId + '&page=' + pageId
    );
  }

  /**
   * Get last friend chat message from friend id
   * @param friendId event id
   */
  public GetLastFriendChatMessagesFromId(friendId: number, pageId = 0) {
    return this.http.get<any>(
      API_URL + '/friend/message/last/?friend_id=' + friendId + '&page=' + pageId
    );
  }

  /**
   * Get all events pins for event
   * @param eventId event id
   */
  public GetEventPinDetail(eventId: number) {
    return this.http.get<any>(API_URL + '/event/get/pin/?event_id=' + eventId);
  }

  /**
   * Get all map pins for activity
   * @param eventId event id
   */
  public GetActivityPinDetail(activityId: number) {
    return this.http.get<any>(
      API_URL + '/activity/get/pin/?activity_id=' + activityId
    );
  }

  /**
  * Get all map pins for hotspot
  * @param eventId event id
  */
  public GetHotspotPinDetail(activityId: number) {
    return this.http.get<any>(
      API_URL + '/activity/get/pin/?activity_id=' + activityId
    );
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public CreateFriendMessage(message: ChatMessage) {
    return this.http.post<any>(API_URL + '/friend/message/create/', message);
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public DeleteFriendMessage(message: ChatMessage) {
    return this.http.post<any>(API_URL + '/friend/message/delete/', message);
  }

  /**
   * Update friend message field
   * @param friendMessage struct friend message
   */
  public UpdateFriendMessage(message: ChatMessage) {
    return this.http.post<any>(API_URL + '/friend/message/update/', message);
  }

  /**
   * Get all group list of event from event id
   * @param eventId event id
   */
  public GetGroupsFromEventId(eventId: number) {
    return this.http.get<any>(API_URL + '/event/group/get/?id=' + eventId);
  }

  /**
   * Get all group list of activity from event id
   * @param eventId event id
   */
  public GetGroupsFromActivityId(eventId: number) {
    return this.http.get<any>(API_URL + '/activity/group/get/?id=' + eventId);
  }

  /**
   * Get group from groupID
   * @param userId id of user
   */
  public GetGroupFromId(groupId: number) {
    return this.http.get<any>(API_URL + '/group/get/?id=' + groupId);
  }

  /**
   * Get groups of eventID
   * @param userId id of user
   */
  public GetUserRelatedHotSpotInfo(userId: number) {
    return this.http.get<any>(API_URL + '/event/group/get/?event_id=' + userId);
  }

  /**
   * Register an user to event
   * @param userId id of user
   */
  public RegisterUserToEvent(userId: number) {
    return this.http.post<any>(API_URL + '/event/group/register/', userId);
  }

  /**
   * Update a group from group object
   * @param userId id of user
   */
  public UpdateGroupFromGroupId(groupData: any) {
    return this.http.post<any>(API_URL + '/group/update/', groupData);
  }

  /**
   * Create a chat group to an event
   * @param userId id of user
   */
  public CreateGroupToActivity(groupData: any) {
    return this.http.post<any>(API_URL + '/group/create/', groupData);
  }

  /**
   * Create a chat group to an event
   * @param userId id of user
   */
  public CreateGroupToEvent(groupData: any) {
    return this.http.post<any>(API_URL + '/group/create/', groupData);
  }

  /**
   * Supprime un chat group d'un event
   * @param userId id of user
   */
  public DeleteGroupFromGroupId(eventId: number) {
    return this.http.post<any>(API_URL + '/group/delete/', eventId);
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public AddMemberToGroup(member: any) {
    return this.http.post<any>(API_URL + '/group/member/add/', member);
  }

  /**
   * Add like to post
   */
  public AddLikeToPost(postId: number, like: string) {
    return this.http.post<any>(API_URL + '/profile/post/like/add/', {
      type: 'post',
      typeTargetId: postId,
      state: like,
    });
  }

  /**
   * Add like to comment
   */
  public AddLikeToComment(userId: number, commentId: number, like: string) {
    return this.http.post<any>(API_URL + '/profile/post/like/add/', {
      owner: userId,
      type: 'comment',
      typeTargetId: commentId,
      state: like,
    });
  }

  /**
   * Add new member to event
   */
  public AddMemberToEvent(member: any) {
    return this.http.post<any>(API_URL + '/event/register/add/', member);
  }

  /**
   * Delete a member from event
   */
  public DeleteMemberToEvent(member: any) {
    return this.http.post<any>(API_URL + '/event/register/delete/', member);
  }

  /**
   * Add new member to activity
   */
  public AddMemberToActivity(member: any) {
    return this.http.post<any>(API_URL + '/activity/register/add/', member);
  }

  /**
   * Delete a member from activity
   */
  public DeleteMemberToActivity(member: any) {
    return this.http.post<any>(API_URL + '/activity/register/delete/', member);
  }

  /**
   * Delete a member from group
   */
  public DeleteMemberToGroup(member: any) {
    return this.http.post<any>(API_URL + '/group/member/delete/', member);
  }

  /**
   * Delete a member from community
   */
  public DeleteMemberToCommunity(member: any) {
    return this.http.post<any>(API_URL + '/community/member/delete/', member);
  }

  /**
   * Get profile page informations
   * @param userId id of user
   */
  public getProfilePageById(userId: number) {
    return this.http.get<any>(API_URL + '/profile/get/?user_id=' + userId);
  }

  /**
   * Add a follower
   * @param userId id of user
   */
  public addFolow(follow: Follow) {
    return this.http.post<any>(API_URL + '/follow/create/', follow);
  }

  /**
   * Delete follower
   * @param userId id of user
   */
  public deleteFolow(followId: number) {
    return this.http.post<any>(
      API_URL + '/follow/delete/?follow_id=' + followId,
      null
    );
  }

  /**
   * Get a community from ID
   * @param communityId Community id
   * @returns community
   */
  public getCommunityDetailById(communityId: number) {
    return this.http.get<any>(
      API_URL + '/community/get/?community_id=' + communityId
    );
  }

  /**
   * Create a new community page
   * @param userId id of user
   */
  public CreateCommunityPage(communityData: any) {
    return this.http.post<any>(API_URL + '/community/create/', communityData);
  }

  /**
   * Get all chat group of user
   * @param page
   * @param pagesize
   * @returns
   */
  public getGroupOfUser(page = 1, pagesize = 12) {
    return this.http.get<any>(
      API_URL + '/group/get/my/?page=' + page + '&pagesize=' + pagesize
    );
  }

  /**
   * Create a new 'ADD' request to community
   * @param userId id of user
   */
  public CreateAddRequest(communityData: any) {
    return this.http.post<any>(
      API_URL + '/community/member/addrequest/',
      communityData
    );
  }

  /**
   * Update the community
   * @param userId id of user
   */
  public UpdateCommunityPage(communityData: any) {
    return this.http.put<any>(
      API_URL + '/community/update/?community_id=' + communityData.ID,
      communityData
    );
  }

  /**
   * Delete community
   * @param userId id of user
   */
  public DeleteCommunityPage(id: number) {
    return this.http.post<any>(
      API_URL + '/community/delete/?community_id=' + id,
      {}
    );
  }

  /**
   * Lance une requet http pour effectuer une recherche dans la base de données et retourne les element
   */
  public searchUserAndEvent(searchText: string) {
    return this.http
      .get<any>(API_URL + '/search/?searchText=' + searchText)
      .pipe(
        map((request) => {
          if (request != null) {
            if (request.data == undefined) {
              request.data = [];
            }
          }
        })
      );
  }

  /**
   * Requete http qui recupere tout les events de la map
   */
  public getAllPinsEvent(paramFilter: any) {
    paramFilter.start_date = Math.floor(paramFilter.start_date);
    paramFilter.end_date = Math.floor(paramFilter.end_date);
    const rq = new URLSearchParams(paramFilter).toString();

    return this.http.get<any>(API_URL + '/event/get/all/pin/?' + rq).pipe(
      map((request) => {
        return request;
      })
    );
  }

  /**
   * Requete http qui recupere tout les events de la map
   */
  public getAllPinsActivity(paramFilter: any) {
    paramFilter.start_date = Math.floor(paramFilter.start_date);
    paramFilter.end_date = Math.floor(paramFilter.end_date);
    const rq = new URLSearchParams(paramFilter).toString();

    return this.http.get<any>(API_URL + '/activity/get/all/pin/?' + rq).pipe(
      map((request) => {
        return request;
      })
    );
  }

  /**
   * Recupere toutes les events
   * @param page page id
   * @param pagesize page taille
   * @param searchRequest request
   * @returns
   */
  public getAllEvents(page: number, pagesize = 12, searchRequest: any) {
    const rq = new URLSearchParams(searchRequest).toString();
    return this.http.get<any>(
      API_URL +
      '/event/get/all/?page=' +
      page +
      '&pagesize=' +
      pagesize +
      '&' +
      rq
    );
  }

  /**
   * Recupere toutes les activité
   * @param page page id
   * @param pagesize page taille
   * @param searchRequest request
   * @returns
   */
  public getAllActivities(page: number, pagesize = 12, searchRequest: any) {
    const rq = new URLSearchParams(searchRequest).toString();
    return this.http.get<any>(
      API_URL +
      '/activity/get/all/?page=' +
      page +
      '&pagesize=' +
      pagesize +
      '&' +
      rq
    );
  }

  /**
   * Requete http qui recupere tout les events de la map
   */
  public getEventDetails(eventId: number) {
    return this.http.get<any>(API_URL + '/event/get/?id=' + eventId);
  }

  /**
   * Requete http qui recupere tout les events de la map
   */
  public getActivityDetails(activityId: number) {
    return this.http.get<any>(API_URL + '/activity/get/?id=' + activityId);
  }

  /**
   * Permet de créer une notification
   * @param notif Notification object
   */
  /*public createNotification(notif: INotification) {
    return this.http.post<any>(API_URL + '/notification/create/', notif);
  }*/

  /**
   * Permet de créer une notification
   * @param notif Notification object
   */
  /*public updateNotification(notif: INotification) {
    return this.http.post<any>(API_URL + '/notification/update/', notif);
  }*/

  /**
   * Créer un nouveau bookmark pour un user (ajout au favori d'un event ou activité)
   * @param notif Notification object
   */
  public addBookmark(bookmark: Bookmark) {
    return this.http.post<any>(API_URL + '/bookmark/add/', bookmark);
  }

  /**
   * Recupere les bookmarks d'un user
   * @param notif Notification object
   */
  public getBookmarks(ownerType: string) {
    return this.http.get<any>(API_URL + '/bookmark/get/?owner_type=' + ownerType);
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public UpdateCommunityMemberGrade(memberGrade: any) {
    return this.http.put<any>(
      API_URL + '/community/grade/update/?grade_id=' + memberGrade.ID,
      memberGrade
    );
  }

  /**
   * Get user information from user id
   * @param userId id of user
   */
  public UpdateCommunityMember(member: any) {
    return this.http.put<any>(
      API_URL + '/community/member/update/?member_id=' + member.ID,
      member
    );
  }

  /**
   * Handle an ApiService error
   * @param error HttpErrorResponse
   */
  private handleError(error: HttpErrorResponse) {
    console.error('ApiService::handleError', error);
    return error;
  }
}
