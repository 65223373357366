<div *ngIf="showMenu" (blur)="showMenu = false" class="emote_choice_menu">
  <!--<div class="emote_nav_category">
    <ul>
      <li>a</li>
      <li>b</li>
      <li>c</li>
    </ul>
  </div>-->
  <div class="emote_searcher">
    <input type="text" [(ngModel)]="emoteSearchStr" (keyup)="searchEmote()" class="form-control" placeholder="Rechercher une emote..." />
    <div (click)="close()" class="close"><img src="/assets/images/cancel_white.png" /></div>
  </div>
  <div class="emote_list">
    <ul>
      <li (click)="emoteSelected(emote)" *ngFor="let emote of emojiToShow"><img height="20px" width="20px" src="assets/emoji/images/{{emote?.image}}" /></li>
    </ul>
  </div>
</div>
