import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserDetails } from '../models/user';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpResponse } from 'src/app/core/modules/http/response';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Services
  private api = inject(ApiService);
  private http = inject(HttpClient);

  // Variables
  public myself: WritableSignal<UserDetails> = signal(<UserDetails>{});
  public fbUser: any;
  public friends: UserDetails[];
  public myselfUpdateSubject = new BehaviorSubject<UserDetails | undefined>(
    undefined
  );
  public friendsSubject = new BehaviorSubject<any[]>([]);

  constructor() {
    this.friends = [];
  }

  /**
   * Recupere l'instance de l'user
   */
  public get userDetails(): UserDetails | undefined {
    return this.myself();
  }

  /**
   * Récupere les informations de l'utilisateur
   */
  public async GetME() : Promise<UserDetails | undefined> {
    var userDetails = await lastValueFrom(
      this.http.post<HttpResponse<UserDetails>>(environment.apiUrl + '/me/', {
        UserId: 'test',
      })
    );

    if (userDetails.status && userDetails.data) {
      this.myself.set(userDetails.data);
      this.myselfUpdateSubject.next(this.myself());
    }

    return userDetails.data;
  }

  public isMyFriend(user: UserDetails) {
    var self = this;
    let userFind = false;
    this.friends.forEach((friend) => {
      if (friend.ID == user.ID && friend.ID != self.myself()?.ID) {
        userFind = true;
        return;
      }
    });

    return userFind;
  }

  public UpdateFriendState(friend: UserDetails) {
    let friendI = this.friends.findIndex((f) => f.ID == friend.ID);
    this.friends[friendI] = friend;
  }

  /**
   * Propagation du profil utilisateur apres une mise a jour
   */
  public emitMyselfSubject() {
    this.myselfUpdateSubject.next(this.myself());
  }

  /**
   * Propagation des friends après une mise a jour
   */
  public emitFriendsSubject() {
    this.friendsSubject.next(this.friends);
  }

  /**
   * Charge le profil de l'utilisateur depuis l'api
   */
  public LoadMyselfFromServer() {
    this.http
      .post<any>(API_URL + '/user/checkConnection', {})
      .pipe(
        map((request) => {
          if (request.data != null) {
            this.myself = request.data;
            this.emitMyselfSubject();
          }
        })
      )
      .subscribe();
  }

  /**
   * Charge les ami(e)s de l'utilisateurs depuis l'api
   */
  public LoadFriendsFromServer() {
    /*this.http
      .get<any>(API_URL + '/friend/get/')
      .pipe(
        map((request) => {
          if (request?.data != undefined && this.myself()?.friends != undefined) {
            this.myself.mutate(my => my.friends = request.data);
            this.emitFriendsSubject();
          }
        })
      )
      .subscribe();*/
  }

  /**
   * Recupere les demande d'ami en attente
   */
  public GetPendingFriendRequest() {
    return this.http.get<any>(API_URL + '/friend/request/get/');
  }

  /**
   * Charge les events de l'utilisateur
   */
  public GetUserEventList() {
    return this.http.get<any>(API_URL + '/event/getUserEvents/');
  }

  /**
   * Accepte une requete d'ami
   * @param data
   */
  public AcceptFriendRequest(data: any) {
    return this.http.post<any>(API_URL + '/friend/request/accept/', data);
  }

  /**
   * Met à jour le mot de passe a partir d'un token
   * @param data
   */
  public UpdateUserPassword(newPassword: string, token: string) {
    let options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
    return this.http.post<any>(
      API_URL + '/user/update/password/',
      { token: token, password: newPassword },
      options
    );
  }

  /**
   * Met à jour l'adresse mail a partir d'un token
   * @param data
   */
  public UpdateUserEmail(newEmail: string, token: string) {
    return this.http.post<any>(API_URL + '/user/update/email/', {
      token: token,
      email: newEmail,
    });
  }

  /**
   * Ajoute un ami avec son ID
   */
  public CreateFriendRelationRequest(userId: number, success: Function) {
    this.http
      .post<any>(API_URL + '/friend/request/create/', { friendId: userId })
      .pipe(
        map((request) => {
          if (request.status) {
            console.log('Friend request send');
            this.LoadFriendsFromServer();
            if (success != null) success();
          } else {
            console.log(
              "Can't create user relation, reason : " + request.message
            );
          }
        })
      )
      .subscribe();
  }

  /**
   * Remove friend from friendList
   * @param userId Friend id to remove
   */
  public RemoveFriendRelation(userId: number) {
    return this.http.post<any>(API_URL + '/friend/delete/', { userId: userId });
  }

  /**
   * Sauvegarde le profil user et partage l'info en tcp
   */
  public SaveAndShareMyself(): void {
    if (this.myself) {
      const user = this.myself();
      //user.friends = null;
      // Save data to api
      this.http
        .post<any>(API_URL + '/user/update/', user)
        .pipe(
          map((request) => {
            if (request.status) {
              this.emitMyselfSubject();
            } else {
              console.log(request.message);
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * Handle an ApiService error
   * @param error HttpErrorResponse
   */
  private handleError(error: HttpErrorResponse) {
    console.error('ApiService::handleError', error);
    return of(error);
  }

  /**
   * Envoi un mail avec un lien pour modifier l'email, 5min pour le faire
   * @param newMail new email
   */
  public changeUserMailRequest(newMail: string) {
    return this.http.post<any>(API_URL + '/reset/mail/', { email: newMail });
  }

  /**
   * Envoi un mail avec un lien pour modifier le mot de passe, 5min pour le faire
   * @param newPassword new email
   */
  public changeUserPasswordRequest(emailToSend: string) {
    return this.http.post<any>(API_URL + '/reset/password/', {
      emailToSend: emailToSend,
    });
  }

  /**
   * Envoi un mail avec un lien pour modifier proceder à la suppression de compte
   */
  public deleteAccountRequest() {
    return this.http.get<any>(API_URL + '/user/delete/');
  }

  /**
   * Envoi un mail avec un lien pour modifier proceder à la suppression de compte
   */
  public deleteAccountConfirmRequest(token: string) {
    return this.http.post<any>(API_URL + '/user/delete/confirm', {
      token: token,
    });
  }
}
