import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import * as emoji from 'src/assets/emoji/emoji2.json';

@Component({
  selector: 'app-emoticon-choice',
  templateUrl: './emoticon-choice.component.html',
  styleUrls: ['./emoticon-choice.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule
  ]
})
export class EmoticonChoiceComponent implements OnInit {

  @Input("showMenu")
  public showMenu : boolean = false;
  @Output("showMenuChange")
  showMenuChange = new EventEmitter();

  @Input("textfield")
  public textfield : any;
  @Output("textfieldChange")
  textfieldChange = new EventEmitter();
	public emoji: any[] = [];
  public emojiWidth = "20px";
  public emojiHeight = "20px";
  public configLoaded = false;
  public emoteSearchStr = "";
  public emojiToShow: any[] = [];

  constructor() { }

  ngOnInit() {
    this.emoji = (<any>emoji);
    this.emojiToShow = (<any>emoji);
    this.configLoaded = true;
  }

  public searchEmote()
  {
    this.emojiToShow = this.emoji.filter((emo: any) => {
      if (emo.short_name.indexOf(this.emoteSearchStr) != -1)
        return emo;
    });
  }

  public close()
  {
    this.showMenu = false;
    this.showMenuChange.emit(false);
  }

  public emoteSelected(emote: any)
  {
    this.showMenu = false;
    this.showMenuChange.emit(false);

    this.textfield = emote.image;
    this.textfieldChange.emit(this.textfield);
  }
}
