<div class="block_info">
  <div class="block_info_title">
    <div class="block_title_text">
      <img width="40px" height="40px" src="/assets/images/notification.svg" />
      Notification
    </div>
  </div>
  <div class="block_info_content">
    <!--<app-edit-field #notifZoneEnableField (success)="toggleNotifZone($event)" [editTemplate]="notificationZoneEnableTemplate" [value]="m_myself.settings.notifZoneEnable == true ? 'Activé' : 'Désactivé'" [name]="'Notification des événements ou activités proches de chez vous'" [icon]="'/assets/images/notification_gps.svg'">
    </app-edit-field>
    <app-edit-field #notifZoneDistance (success)="updateNotifZoneDistance($event)" [editTemplate]="notificationDistanceTemplate" [value]="m_myself.settings.notifZoneDistance" [name]="'USER_SETTINGS.label_notif_zone_km' | translate" [icon]="'/assets/images/distance.svg'">
    </app-edit-field>-->
    <!--
      Active ou pas les notification de zone
    
    <ng-template #notificationZoneEnableTemplate>
      <h3>Modification</h3>
      <form [formGroup]="notificationForm">
        <div class="content">
          <div class="alert alert-danger">Vous pouvez activer ou désactiver les notifications proche de chez vous</div>
          <div #notification class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="notificationZoneEnable">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="true"> Activé
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="false"> Désactivé
            </label>
          </div>
        </div>
      </form>
      <div>
        <button (click)="notifZoneEnableField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="notifZoneEnableField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone
    
    <ng-template #notificationDistanceTemplate>
      <h3>Modification</h3>
      <form [formGroup]="notificationForm">
        <div class="content">
          <ngx-slider formControlName="notifZoneDistance" [options]="options"></ngx-slider>
          <b>{{f.notifZoneDistance.value}} Km</b>
        </div>
      </form>
      <div>
        <button (click)="notifZoneDistance.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="notifZoneDistance.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
  </div>
</div>
