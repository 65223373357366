<div class="block_info">
  <div class="block_info_title">
    <div class="block_title_text">
      <img
        width="40px"
        height="40px"
        src="/assets/images/confidentiality.svg"
      />
      Confidentialité
    </div>
  </div>
  <div *ngIf="f" class="block_info_content">
    <!--<app-edit-field #profileFriendCanPostField (success)="updateProfileFriendCanPost($event)" [editTemplate]="profileFriendCanCreatePostTemplate" [value]="m_myself.settings.profileFriendCanPost ? 'Oui' : 'Non'" [name]="'Vos ami(e)s peuvent-il créer un poste sur votre mur ?'" [icon]="'/assets/images/canPostAdd.svg'">
    </app-edit-field>
    <app-edit-field #profileFacebookLinkField (success)="updateFacebookLink($event)" [editTemplate]="profileFacebookLinkTemplate" [value]="m_myself.settings.profileFacebookLink" [name]="'Votre page Facebook'" [icon]="'/assets/images/social_icon/012-facebook.svg'">
    </app-edit-field>
    <app-edit-field #profileTwitterLinkField (success)="updateTwitterLink($event)" [editTemplate]="profileTwitterLinkTemplate" [value]="m_myself.settings.profileTwitterLink" [name]="'Votre page Twitter'" [icon]="'/assets/images/social_icon/029-twitter.svg'">
    </app-edit-field>
    <app-edit-field #profileInstagramLinkField (success)="updateInstagramLink($event)" [editTemplate]="profileInstagramLinkTemplate" [value]="m_myself.settings.profileInstagramLink" [name]="'Votre page Instagram'" [icon]="'/assets/images/social_icon/020-instagram.svg'">
    </app-edit-field>
    <app-edit-field #profileWhoSeeFuturPostField (success)="updateWhoSeeFuturPost($event)" [editTemplate]="whoSeeFuturPostTemplate" [value]="m_myself.settings.profileWhoSeeFuturPost | whoSeeFuturPost" [name]="'Qui peut voir vos futur post ?'" [icon]="'/assets/images/key.svg'">
    </app-edit-field>
    <app-edit-field #profileWhoSendRequestFriendField (success)="updateWhoSendRequestFriend($event)" [editTemplate]="whoSendRequestTemplate" [value]="m_myself.settings.profileWhoSendRequestFriend | whoSeeFuturPost" [name]="'Qui peut vous envoyer une demande d\'ami ?'" [icon]="'/assets/images/key.svg'">
    </app-edit-field>
    <app-edit-field #forumSignatureField (success)="updateForumSignature($event)" [editTemplate]="forumSignatureTemplate" [value]="m_myself.settings.forumSignature" [name]="'Votre signature de forum'" [icon]="'/assets/images/sign.svg'">
    </app-edit-field>-->

    <!--
      Active la possibilité des amies de poster des post sur le propre mur
    
    <ng-template #profileFriendCanCreatePostTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <div #notification class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="profileFriendCanPost">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="true"> Activé
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="false"> Désactivé
            </label>
          </div>
        </div>
      </form>
      <div>
        <button (click)="profileFriendCanPostField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileFriendCanPostField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone

    <ng-template #profileFacebookLinkTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <input class="form-control" type="text" formControlName="profileFacebookLink" />
        </div>
      </form>
      <div>
        <button (click)="profileFacebookLinkField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileFacebookLinkField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone
    
    <ng-template #profileTwitterLinkTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <input class="form-control" type="text" formControlName="profileTwitterLink" />
        </div>
      </form>
      <div>
        <button (click)="profileTwitterLinkField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileTwitterLinkField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone
    
    <ng-template #profileInstagramLinkTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <input class="form-control" type="text" formControlName="profileInstagramLink" />
        </div>
      </form>
      <div>
        <button (click)="profileInstagramLinkField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileInstagramLinkField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone
    
    <ng-template #whoSeeFuturPostTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <div #notification class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="profileWhoSeeFuturPost">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'all'"> Tout le monde
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'friend'"> Seulement vos ami(e)s
            </label>
          </div>
        </div>
      </form>
      <div>
        <button (click)="profileWhoSeeFuturPostField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileWhoSeeFuturPostField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone

    <ng-template #whoSendRequestTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <div #notification class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="profileWhoSendRequestFriend">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'all'"> Tout le monde
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'friend'"> Seulement vos ami(e)s
            </label>
          </div>
        </div>
      </form>
      <div>
        <button (click)="profileWhoSendRequestFriendField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="profileWhoSendRequestFriendField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Distance pour les notification de zone
    
    <ng-template #forumSignatureTemplate>
      <h3>Modification</h3>
      <form [formGroup]="confidentialityForm">
        <div class="content">
          <textarea class="form-control" type="text" formControlName="forumSignature"></textarea>
        </div>
      </form>
      <div>
        <button (click)="forumSignatureField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="forumSignatureField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
  </div>
</div>
