import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    NgbTooltipModule
  ]
})
export class UserSettingsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
