import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MapService } from 'ngx-mapbox-gl';
import { ApiService } from 'src/app/core/services/api.service';
import { AppService } from 'src/app/core/services/app.service';
import { EditFieldComponent } from 'src/app/core/utils/edit-field/edit-field.component';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-personnal',
  templateUrl: './personnal.component.html',
  styleUrls: ['./personnal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    EditFieldComponent,
  ],
})
export class PersonnalComponent implements OnInit {
  // Services
  public _appService = inject(AppService);
  public _apiService = inject(ApiService);
  public _userService = inject(UserService);
  public _mapService = inject(MapService);
  public _formBuilder = inject(FormBuilder);
  public _changeDetectorRef = inject(ChangeDetectorRef);

  public m_myself = null;
  public _personnalForm = signal<FormGroup>(
    this._formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(3)]],
      lastname: ['', [Validators.required, Validators.minLength(3)]],
      birthdayDate: [
        new NgbDate(
          new Date(
            this._userService.myself()?.birthdayDate ?? ''
          ).getUTCFullYear(),
          new Date(this._userService.myself()?.birthdayDate || '').getUTCMonth() +
            1,
          new Date(this._userService.myself()?.birthdayDate || '').getUTCDate() +
            1
        ),
        Validators.required,
      ],
      gender: [this._userService.myself()?.sexe, Validators.required],
      phoneNumber: [this._userService.myself()?.phoneNumber, Validators.required],
      country: [
        this._userService.myself()?.country,
        [Validators.required, Validators.minLength(3)],
      ],
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(6)],
      ],
      confirmEmail: [
        '',
        [Validators.required, Validators.email, Validators.minLength(6)],
      ],
      profileFacebookLink: [
        this._userService.myself()?.settings?.profileFacebookLink,
        [Validators.minLength(10)],
      ],
      profileInstagramLink: [
        this._userService.myself()?.settings?.profileInstagramLink,
        [Validators.minLength(10)],
      ],
      profileTwitterLink: [
        this._userService.myself()?.settings?.profileTwitterLink,
        [Validators.minLength(10)],
      ],
      conjugalStatus: [
        this._userService.myself()?.conjugalStatus,
        [Validators.required],
      ],
      customGender: [
        this._userService.myself()?.customGender,
        [Validators.minLength(3)],
      ],
    })
  );
  public addressError: any;
  public userAddress: any;
  public countries = [];
  public countrySelected: any = null; /*
  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.countries
              .filter(
                (v) =>
                  v.translations.fra.common
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(
                      term
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                    ) > -1
              )
              .slice(0, 10)
              .map((a) => a)
      )
    );
  formatter = (x: any) => x.translations.fra.common;*/

  constructor() {
    // Return all countries list
    /*this.countries = this._appService.GetCountries;
    this.m_myself = userService.myself;*/
  }
  /*
  onChangeAddress(address) {
    this.userAddress = address;
  }*/

  ngOnInit(): void {}

  // convenience getter for easy access to form fields
  get f() {
    return this._personnalForm().controls as any;
  } /*

  /*
  public onVisibilityChange(state, field) {
    this.userService.myself.settings[field] = state;
    this.userService.SaveAndShareMyself();
  }*/ /*
  /*
  public updateCountry()
  {
    this.userService.myself.country = {
      ownerID: this.userService.myself.ID,
      cca2: this.countrySelected.cca2,
      cca3: this.countrySelected.cca3
    };
    this.userService.SaveAndShareMyself();
  }*/ /*

  /**
   * Lastname
   */ /*
  /*public updateLastname(value)
   {
     if (this._personnalForm().get("lastname").valid)
     {
      this.userService.myself.lastname = this.personnalForm.get("lastname").value;
      this.userService.SaveAndShareMyself();
     }
   }*/ /*

  /**
   * Firstname
   */ /*
  /* public updateFirstname(value)
  {
    if (this.personnalForm.get("firstname").valid)
    {
      this.userService.myself.firstname = this.personnalForm.get("firstname").value;
      this.userService.SaveAndShareMyself();
    }
  }*/ /*

  /**
   * Genre
   */ /*
  /*public updateGender(gender: string)
  {
    if (this.personnalForm.get("gender").value == 2)
    {
      this.userService.myself.sexe = this.personnalForm.get("gender").value;
      this.userService.myself.customGender = this.personnalForm.get("customGender").value;
      this.userService.SaveAndShareMyself();
    } else {
      this.userService.myself.sexe = this.personnalForm.get("gender").value;
      this.userService.SaveAndShareMyself();
    }
  }*/ /*

  /**
   * BirthdayDate
   */
  /*public updateBirthdayDate(value)
  {
    var date: NgbDate = this.personnalForm.get("birthdayDate").value;

    this.userService.myself.birthdayDate = new Date(date.year, date.month - 1, date.day);
    this.userService.SaveAndShareMyself();
  }*/

  /**
   * Mobile
   */ /*
  public updatePhoneNumber(value)
  {
    this.userService.myself.phoneNumber = this.personnalForm.get("phoneNumber").value;
    this.userService.SaveAndShareMyself();
  }*/ /*

  /**
   * ConjugalStatus
   */ /*
  public updateConjugalStatus(value)
  {
    this.userService.myself.conjugalStatus = this.personnalForm.get("conjugalStatus").value;
    this.userService.SaveAndShareMyself();
  }*/ /*

  /**
   * Birthday location
   */ /*
  public updateLocation()
  {
    if (this.userService.myself.location == null)
      this.userService.myself.location = {};

    this.userService.myself.location.x = this.userAddress.geometry.coordinates[0].toString();
    this.userService.myself.location.y = this.userAddress.geometry.coordinates[1].toString();
    this.userService.myself.location.name = this.userAddress.place_name;
    this.userService.myself.location.userId = this.m_myself.ID;
    this.userService.myself.location.ownertype = "user";

    this.userService.SaveAndShareMyself();
  }*/
}
