import { CommonModule } from "@angular/common";
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { NgbModal, NgbPopoverModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { WebcamModule } from "ngx-webcam";
import { PublicDataPipe, UtilityService, getBase64 } from "src/app/core/utils/tools";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/core/services/api.service";
import * as emoji from 'src/assets/emoji/emoji2.json';
import { EmoticonChoiceComponent } from "../emoticon-choice/emoticon-choice.component";
import { ChatMessage, Media, ImageType } from "src/app/features/user/models/user";
import { ProfilePageService } from "src/app/features/user/services/profile-page.service";
import { UserService } from "src/app/features/user/services/user.service";
import { CameraComponent } from "../../modules/camera/components/camera/camera.component";

@Component({
  selector: 'app-message-writer',
  templateUrl: './message-writer.component.html',
  styleUrls: ['./message-writer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    NgbPopoverModule,
    NgbTooltipModule,
    EmoticonChoiceComponent
  ]
})
export class MessageWriterComponent implements OnInit {

  // Services
  public notif = inject(ToastrService);
  private userService = inject(UserService);
  private apiService = inject(ApiService);
  private modalService = inject(NgbModal);
  private profilePageService = inject(ProfilePageService);
  private utility = inject(UtilityService);

  @Output("onSendAction") onSendMessage = new EventEmitter<ChatMessage>();
  @Output("onUploadFilesAction") onUploadFiles = new EventEmitter<ChatMessage>();
  @Output("onMakeCall") onMakeCall = new EventEmitter<any>()
  @ViewChild('chatTextfieldElem', { static: false }) chatTextfieldElem?: ElementRef;
  showEmojiPopup: boolean = false;
  @Input("enterSendEnable")
  m_enterSendEnable:boolean = false;
  @Input("value")
  m_messageTextfield = "";
  m_defaultTextFieldHeight = 30;
  m_textfieldHeight = "30px";
  m_defaultChatZoneHeight = 213;
  @Input("onlyText")
  public onlytext: boolean = false;
  @Input("canMakeCall")
  public canMakeCall: boolean = false;
  @Input("canSendMedia")
  public canSendMedia: boolean = false;
  @Output("onMessageChange")
  onMessageChange = new EventEmitter<any>()
  @Input("canSend")
  m_canSend: boolean = true;
  medias: Media[] = [];
  mediaPreview: boolean = false;
  imageSaveInProgress: boolean = false;
  isScrollable: boolean = false;

  constructor() {
    this.showEmojiPopup = false;
  }

  ngOnInit(): void {

  }

  public openModal(modal: any) {
    this.modalService.dismissAll();
    this.modalService.open(modal, { size: "lg", centered: true, scrollable: true });
  }

  /**
   * Ouverture de la liste d'emote
   */
  public openEmojiPopup() {
    this.showEmojiPopup = true;
  }

  /**
   * Ouvre une fenetre d'appel
   */
  public openCallWindow() {
    this.onMakeCall.next(true);
    //window.open('http://localhost:4200/call/', '_blank', 'toolbar=0,location=0,menubar=0,width=600px,height=700px');
  }

  /**
   * Quand on appui sur une touche dans le champ de texte
   * @param event
   */
  public onKeyUp(event: any) {
    //if (event.key !== "Enter")
    {
      this.m_messageTextfield = event.target.textContent;
      this.m_textfieldHeight = this.calcHeight(this.chatTextfieldElem?.nativeElement.innerHTML) + "px";
      this.onMessageChange.next(this.chatTextfieldElem?.nativeElement.innerHTML);
    }
  }

  // Dealing with Textarea Height
  public calcHeight(value: string) {
    let numberOfLineBreaks = (value.match(/<div>/g) || []).length;
    numberOfLineBreaks += (value.match(/<br>/g) || []).length > 0 ? (value.match(/<br>/g) || []).length - 1 : 0;
    // min-height + lines x line-height + padding + border
    let newHeight = 30 + (numberOfLineBreaks > 1 ? numberOfLineBreaks : numberOfLineBreaks) * 19;
    if (newHeight >= 170)
    {
      this.isScrollable = true;
    } else {
      this.isScrollable = false;
    }
    return newHeight;
  }

  /**
   * Envoi un message text à un ami
   * Ajout du message à la bdd via l'api puis si tout est ok on l'envoi en tcp
   * @param text Texte à envoyer
   */
  public sendMessage(e: any, text: string, isEnter = false) {
    var self = this;

    if (!this.m_enterSendEnable && isEnter)
      return;

    if (this.onlytext) {
      var message: ChatMessage = {
        text: this.chatTextfieldElem?.nativeElement.innerHTML,
        type: "tx",
        FromUser: self.userService.myself(),
        messageType: "text"
      };

      this.m_messageTextfield = "";

      // On déclanche l'envoie le message
      this.onSendMessage.emit(message);
      return;
    }

    e.preventDefault();


    if (this.chatTextfieldElem?.nativeElement.textContent.trim().length == 0) {
      this.m_messageTextfield = "";
      this.chatTextfieldElem.nativeElement.innerHTML = "";
      return;
    }

    this.m_messageTextfield = text;

    // Supprime le br de fin ajouté automatiquement à cause de div editable
    if (this.m_messageTextfield.lastIndexOf("<br>") != -1)
      this.m_messageTextfield = this.m_messageTextfield.substring(this.m_messageTextfield.lastIndexOf("<br>") - 4, this.m_messageTextfield.lastIndexOf("<br>"));

    // Si un emote est détecté, alors on le remplace par son image
    this.detectEmoji();
    //console.log(this.chatTextfieldElem.nativeElement.selectionStart);
    // Créer l'objet message
    var message: ChatMessage = {
      text: this.m_messageTextfield,
      type: "tx",
      FromUser: self.userService.myself(),
      messageType: "text",
      files: this.medias
    };

    // On déclanche l'envoie du message
    this.onSendMessage.emit(message);

    // On vide le champ
    this.m_messageTextfield = "";
  }

  /**
   * Permet la detection d'emote dans le champ de texte
   */
  public detectEmoji() {
    var emojis = (<any>emoji);
    emojis = emojis.default;

    //var emojis = [];
    for (var i = 0; i < emojis.length; i++) {
      if (emojis[i].texts != undefined) {
        for (let u = 0; u < emojis[i].texts.length; u++) {
          while (this.m_messageTextfield.indexOf(emojis[i].texts[u]) != -1) {
            this.m_messageTextfield = this.m_messageTextfield.replace(emojis[i].texts[u], "<img class='emoji_image' width='18px' height='18px' src='assets/emoji/images/" + emojis[i].image + "'/>");
            break;
          }
        }
      }
      //todo
      /*if (emojis[i].short_names != undefined) {
        for(let u = 0; u < emojis[i].short_names.length; u++)
        {
          while (this.chatTextfieldElem.nativeElement.textContent.indexOf(":" + emojis[i].short_names[u] + ":") != -1)
          {
            this.m_messageTextfield = this.chatTextfieldElem.nativeElement.textContent.replace(":" + emojis[i].short_names[u] + ":", "<img class='emoji_image' width='18px' height='18px' src='assets/emoji/images/" + emojis[i].image + "'/>");
            break;
          }
        }
      }*/
    }
  }

  /**
   * Déclanché quand on selectionne un emote
   * @param emote
   */
  public onEmoteAdded(emote: any) {
    this.chatTextfieldElem?.nativeElement.focus();
    var selection = window.getSelection();
    var range = selection?.getRangeAt(0);//Find the focus position
    var span = document.createElement('span');
    span.innerHTML = "<img width='18px' height='18px' src='assets/emoji/images/" + emote + "'/>";
    range?.insertNode(span);
    this.chatTextfieldElem?.nativeElement.focus();
    selection?.setPosition(span, 1);
    //this.chatTextfieldElem.nativeElement.innerHTML = this.chatTextfieldElem.nativeElement.innerHTML + ;
  }

  public removeMedia(image: any) {
    this.medias = this.medias.filter((img) => img !== image);
    if (this.medias.length == 0)
    {
      this.mediaPreview = false;
    }
  }

  /**
   * Permet l'envoi de fichier (tout type) depuis le chat
   * @param files Fichier à envoyer
   */
  public handleFiles(files: any | undefined) {
    var self = this;
    files = files.target.files
    for (var i = 0; i < files.length; i++) {
      getBase64(files[i], (file: any) => {
        this.medias.push({
          type: ImageType.userPost,
          uploadProgress: 0,
          base64: file.result,
          data: file.file
        });
      }, (ex: any) => {
        alert("error : " + ex)
      })

      this.mediaPreview = true;
    }

    /*var message: ChatMessage = {
      text: this.m_messageTextfield,
      type: "tx",
      messageType: "media",
      files: medias
    };*/

    // On déclanche l'envoie le message
    //this.onUploadFiles.emit(message);
  }
}
