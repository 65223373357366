import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, inject } from '@angular/core';
import { PublicDataPipe, UtilityService } from 'src/app/core/utils/tools';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from '../../camera/components/camera/camera.component';
import { MessageWriterComponent } from 'src/app/core/utils/message-writer/message-writer.component';
import { ProfilePageService } from 'src/app/features/user/services/profile-page.service';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-write-comment',
  templateUrl: './write-comment.component.html',
  styleUrls: ['./write-comment.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0}),
            animate('0.4s ease-out', style({ height: 135.5}))
          ]
        ),
        transition(
          ':leave',
          [
            animate('0.4s ease-in', style({ height: 0}))
          ]
        )
      ]
    )
  ],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    MessageWriterComponent
  ]
})
export class WriteCommentComponent implements OnInit {

  // Services
  public notif = inject(ToastrService);
  private userService = inject(UserService);
  private apiService = inject(ApiService);
  private profilePageService = inject(ProfilePageService);
  private utility = inject(UtilityService);

  // Variables
  @Input("showCreateComment") m_showCreateComment = false;
  @Input("id") m_id: number | undefined = 0;
  @Input("ownerType") m_ownerType: string = "user";
  @ViewChild('newPostText', {static: false}) private m_newCommentTextRef?: ElementRef;
  @Output("onCommentCreate")
  public onCommentCreate: EventEmitter<any> = new EventEmitter<any>();

  public m_newCommentTextValue: string = "";
  public m_canWriteNewComment: boolean = false;
  public m_defaultnewPostTextHeight = 16;
  public m_newPostTextHeight = "16px";

  constructor() { }

  ngOnInit() {
  }

  public onNewPostMessageChange() {
    if (this.m_newCommentTextRef && this.m_newCommentTextRef.nativeElement.style)
    {
      this.m_newCommentTextRef.nativeElement.style.height = "0px";
      this.m_newCommentTextRef.nativeElement.style.height = (this.m_newCommentTextRef.nativeElement.scrollHeight + 24) + 'px';
    }

    if (this.m_newCommentTextValue != "")
      this.m_canWriteNewComment = true;
    else
      this.m_canWriteNewComment = false;
  }

  public createComment(message: any)
  {

    if (message == null)
      return;

    var self = this;
    var comment = {
      text: message.text,
      postId: this.m_id,
      ownerId: this.userService.myself()?.ID,
      ownerType: this.m_ownerType
    };

    this.profilePageService.createComment(comment).subscribe((response: any) => {
      self.onCommentCreate.emit(response.data);
      self.m_newCommentTextValue = "";
      self.m_canWriteNewComment = false;
      //self.profilePageService.LoadProfilePage(self.profilePageService.m_profilePage.ID).subscribe();
    });
  }
}
