<div class="block_info">
  <div class="block_info_title">
    <div class="block_title_text">
      <img width="40px" height="40px" src="/assets/images/settings.svg" />
      Compte
    </div>
  </div>
  <div *ngIf="f" class="block_info_content">
    <!--<app-edit-field #emailField (success)="updateEmail($event)" [editTemplate]="emailTemplate" [value]="m_myself.email" [name]="'E-mail'" [icon]="'/assets/images/email.svg'">
    </app-edit-field>
    <app-edit-field #passwordField (success)="updatePassword($event)" [editTemplate]="passwordTemplate" [value]="'************'" [name]="'Mot de passe'" [icon]="'/assets/images/password.svg'">
    </app-edit-field>
    <app-edit-field #deleteAccountField (success)="deleteAccount($event)" [editTemplate]="deleteAccountTemplate" [name]="'Supprimer votre compte'" [icon]="'/assets/images/delete.svg'">
    </app-edit-field>-->
    <h3>Modification</h3>
    <form [formGroup]="_accountFormGroup()">
      <div class="content">
        <div class="alert alert-warning">
          <h5>
            Vous recevrez un mail de confirmation, suivez les instructions dans
            le mail.
          </h5>
          Vous avez 5 minutes pour effectuer l'opération une fois le mail reçu.
        </div>
        <p>
          <input
            formControlName="password"
            #password
            class="form-control"
            type="password"
            placeholder="Votre mot de passe"
          />
        </p>
        <p>
          <input
            formControlName="confirmPassword"
            #newPassword
            class="form-control"
            type="password"
            placeholder="Confirmez votre mot de passe"
          />
        </p>
        <div
          *ngIf="
            f && f.confirmPassword.errors && f.confirmPassword.errors.mustMatch
          "
          class="alert alert-danger"
        >
          Les deux mot passe ne sont pas identiques
        </div>
        <div
          *ngIf="
            f && f.confirmPassword.errors && f.confirmPassword.errors.minlength
          "
          class="alert alert-danger"
        >
          Taille minimum de 6 caractères
        </div>
      </div>
    </form>
    <div>
      <button class="btn btn-sm btn-primary">Enregistrer</button>
      <button style="margin-left: 10px" class="btn btn-sm btn-danger">
        Annuler
      </button>
    </div>
    <!--
      Password
    
    <ng-template #passwordTemplate>
      <h3>Modification</h3>
      <form [formGroup]="accountForm">
        <div class="content">
          <div class="alert alert-warning">
            <h5>
              Vous recevrez un mail de confirmation, suivez les instructions
              dans le mail.
            </h5>
            Vous avez 5 minutes pour effectuer l'opération une fois le mail
            reçu.
          </div>
          <p>
            <input
              formControlName="password"
              #password
              class="form-control"
              type="password"
              placeholder="Votre mot de passe"
            />
          </p>
          <p>
            <input
              formControlName="confirmPassword"
              #newPassword
              class="form-control"
              type="password"
              placeholder="Confirmez votre mot de passe"
            />
          </p>
          <div
            *ngIf="
              f &&
              f.confirmPassword.errors &&
              f.confirmPassword.errors.mustMatch
            "
            class="alert alert-danger"
          >
            Les deux mot passe ne sont pas identiques
          </div>
          <div
            *ngIf="
              f &&
              f.confirmPassword.errors &&
              f.confirmPassword.errors.minlength
            "
            class="alert alert-danger"
          >
            Taille minimum de 6 caractères
          </div>
        </div>
      </form>
      <div>
        <button
          (click)="passwordField.success()"
          class="btn btn-sm btn-primary"
        >
          Enregistrer
        </button>
        <button
          (click)="passwordField.close()"
          style="margin-left: 10px"
          class="btn btn-sm btn-danger"
        >
          Annuler
        </button>
      </div>
    </ng-template>-->
    <!--
      Email
    
    <ng-template #emailTemplate>
      <h3>Modification</h3>
      <form [formGroup]="accountForm">
        <div class="content">
          <div class="alert alert-warning">
            <h5>
              Vous recevrez un mail de confirmation, suivez les instructions
              dans le mail.
            </h5>
            Vous avez 5 minutes pour effectuer l'opération une fois le mail
            reçu.
          </div>
          <!--<p><input #newEmail formControlName="email" class="form-control" type="text" placeholder="Votre email" /></p>
          <p><input #newEmailVerif formControlName="confirmEmail" class="form-control" type="text" placeholder="Confirmez votre email" /></p>
          <div *ngIf="f && f.confirmEmail.errors && f.confirmEmail.errors.email" class="alert alert-danger">L'adresse mail est incorrecte</div>
          <div *ngIf="f && f.confirmEmail.errors && f.confirmEmail.errors.mustMatch" class="alert alert-danger">Les deux mail ne sont pas identiques</div>
          <div *ngIf="f && f.confirmEmail.errors && f.confirmEmail.errors.minlength" class="alert alert-danger">Taille minimum de 6 caractères</div>
        </div>
      </form>
      <div>
        <button (click)="emailField.success()" class="btn btn-sm btn-primary">
          Je confirme vouloir changer mon adresse mail
        </button>
        <button
          (click)="emailField.close()"
          style="margin-left: 10px"
          class="btn btn-sm btn-danger"
        >
          Annuler
        </button>
      </div>
    </ng-template>-->
    <!--
      Delete account
    
    <ng-template #emailTemplate>
      <h3>Modification</h3>
      <form [formGroup]="accountForm">
        <div class="content">
          <div class="alert alert-warning">
            <h5>
              Vous recevrez un mail de confirmation, suivez les instructions
              dans le mail.
            </h5>
            Vous avez 5 minutes pour effectuer l'opération une fois le mail
            reçu.
          </div>
          <p>
            <input
              #newEmail
              formControlName="email"
              class="form-control"
              type="text"
              placeholder="Votre email"
            />
          </p>
          <p>
            <input
              #newEmailVerif
              formControlName="confirmEmail"
              class="form-control"
              type="text"
              placeholder="Confirmez votre email"
            />
          </p>
          <div
            *ngIf="f && f.confirmEmail.errors && f.confirmEmail.errors.email"
            class="alert alert-danger"
          >
            L'adresse mail est incorrecte
          </div>
          <div
            *ngIf="
              f && f.confirmEmail.errors && f.confirmEmail.errors.mustMatch
            "
            class="alert alert-danger"
          >
            Les deux mail ne sont pas identiques
          </div>
          <div
            *ngIf="
              f && f.confirmEmail.errors && f.confirmEmail.errors.minlength
            "
            class="alert alert-danger"
          >
            Taille minimum de 6 caractères
          </div>
        </div>
      </form>
      <div>
        <button (click)="emailField.success()" class="btn btn-sm btn-primary">
          Enregistrer
        </button>
        <button
          (click)="emailField.close()"
          style="margin-left: 10px"
          class="btn btn-sm btn-danger"
        >
          Annuler
        </button>
      </div>
    </ng-template>-->
    <!--
      Delete account
    
    <ng-template #deleteAccountTemplate>
      <h3>Modification</h3>
      <form [formGroup]="accountForm">
        <div class="content">
          <div class="alert alert-danger">
            <h5>
              Attention, votre compte ne sera plus accessible pour vous et les
              autres !
            </h5>
            <p>
              Cette opération bloque totalement votre compte, vos données seront
              conservés pendant 3 mois, ce qui vous permetra de revenir en
              arrière.<br />
              Vous recevrez un mail de confirmation
            </p>
          </div>
          <p>
            <input
              formControlName="emailDeleteAccount"
              class="form-control"
              type="text"
              placeholder="Confirmer votre adresse mail"
            />
          </p>
        </div>
      </form>
      <div>
        <button
          (click)="deleteAccountField.success()"
          [disabled]="
            !f.emailDeleteAccount.valid &&
            m_myself.email == f.emailDeleteAccount.value
          "
          class="btn btn-sm btn-danger"
        >
          Supprimer ce compte
        </button>
        <button
          (click)="deleteAccountField.close()"
          style="margin-left: 10px"
          class="btn btn-sm btn-secondary"
        >
          Annuler
        </button>
      </div>
    </ng-template>-->
  </div>
</div>
