<div
id="timeline_page"
class="search-post-results">
    <div id="left">
      <div class="user_page_block_info">
          <div class="block_info_title">
              <div class="block_title_text">
                Informations
              </div>
              <div class="about_parameter"></div>
          </div>
          <div class="block_info_content">
            <p class="text_content" *ngIf="m_profile?.conjugalStatus != null && m_profile?.conjugalStatus != ''">{{m_profile?.conjugalStatus == "single" ? "Célibataire" : m_profile?.conjugalStatus == "attached" ? "En couple" : ""}}</p>
              <p class="text_content"><span class="link">{{m_profile?.followers?.length}}</span> followers<br /></p>
              <p class="text_content"><span class="link">{{m_profile?.friends?.length}}</span> ami(e)s<br /></p><br />
              <p class="text_title" *ngIf="m_profile?.description != null && m_profile?.description != ''"><b>A propos :</b></p>
              <p class="text_content" *ngIf="m_profile?.description != null && m_profile?.description != ''">{{m_profile?.description}}</p>
          </div>
      </div>
      <div class="user_page_block_info friend">
        <div class="block_info_title">
            <div class="block_title_text">
                Ami(e)s ({{m_profile?.friends?.length}})
              </div>
              <div class="about_parameter"></div>
        </div>
        <div class="block_info_content">
            <div class="tile" [routerLink]="['../../', friend.ID]" *ngFor="let friend of friends; let i = index">
                <img width="75px" height="75px" src="{{friend.profileImage}}" />
                <div class="name">
                  {{friend.firstname}} {{friend.lastname}}
                </div>
            </div>
        </div>
      </div>
    </div
    ><div id="centered_post_list">
      <!--<app-write-post [ownerId]="m_profile?.ID" [userId]="m_profile?.ID" [ownerType]="'user'"></app-write-post>-->
      <!--<cdk-virtual-scroll-viewport [itemSize]="m_profile?.posts.length" style="height: 484px;width: 100%;">-->
      <app-post [post]="post" *ngFor="let post of m_postsList"></app-post>
      <!--</cdk-virtual-scroll-viewport>-->
    </div
    ><div id="right">
      <div class="user_page_block_info">
          <div class="block_info_title">
              <div class="block_title_text">
                Photos
              </div>
              <div class="about_parameter"></div>
          </div>
          <div class="block_info_content">
              <div class="tile" [routerLink]="['',{ outlets: { modal: ['popupviewer', media.ID]}}]" *ngFor="let media of medias; let i=index">
                  <img width="68px" height="68px" src="{{media.data}}" />
              </div>
          </div>
      </div>
      <div class="user_page_block_info">
        <div class="block_info_title">
          <div class="block_title_text">
            Evenements à venir
          </div>
          <div class="about_parameter"></div>
        </div>
        <div class="block_info_content">
          <!--<div class="event_viewer" *ngFor="let eventRegister of m_profile?.incomingEvents">
            <div *ngIf="eventRegister.event != null">
              <div routerLink="/home/event/{{eventRegister.event.ID}}/info" class="event">
                <div class="event_name">{{eventRegister.event.name}}</div>
                <div class="event_member_counter">{{eventRegister.event.members?.length}} <img style="margin-top: -5px;" width="20px" height="20px" src="../../assets/images/members_hover.png"></div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
</div>
