<div [ngbPopover]="popHoverContent" [container]="'body'" #p="ngbPopover" class="user-label" [openDelay]="500" (mouseleave)="changePopupOnHover(p, false)" (mouseenter)="changePopupState(p, true)" [autoClose]="false"><a class="link">{{m_user?.firstname}} {{m_user?.lastname}}</a></div>
<ng-template #popHoverContent>
    <div (mouseenter)="changePopupOnHover(p, true)" (mouseleave)="changePopupOnHover(p, false)" id="popup-user-info">
        <div id="content">
            <div id="left">
                <img routerLink="/home/user/{{m_user?.ID}}" src="{{m_user?.profileImage | publicData}}" />
            </div>
            <div id="right">
                <b>{{m_user?.firstname}} {{m_user?.lastname}}</b>
                <div id="details">
                  {{m_user?.description}}
                </div>
            </div>
        </div>
        <div id="buttons">
            <ul>
                <li><button [openDelay]="700" placement="right" ngbTooltip="Ajouter en ami" class="btn btn-primary">Ajouter en ami</button></li>
                <li><button [openDelay]="700" placement="right" ngbTooltip="Suivre" class="btn btn-primary">Suivre</button></li>
                <li><button [openDelay]="700" placement="right" ngbTooltip="Discuter" class="btn btn-success"><img src="../../../assets/images/chat.svg" style="margin-top: -5px !important;" width="20px" height="20px"></button></li>
            </ul>
        </div>
    </div>
</ng-template>
