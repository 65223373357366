import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GpsPosition } from 'src/app/core/modules/map/services/map.service';
import { UserDetails } from 'src/app/features/user/models/user';
import { getLocationAsync } from 'src/tools/tools';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Services
  private _deviceService = inject(DeviceDetectorService);
  public _dialog = inject(MatDialog);

  // Variables
  public _user: WritableSignal<UserDetails | null> = signal(null);
  public _userPositionGps: WritableSignal<GpsPosition | null> = signal(null);

  /**
   * Ouvre un dialog à partir d'un template
   * @param type Type du dialog
   * @param config Config du dialog
   * @returns
   */
  public OpenDialog<T>(
    type: ComponentType<T>,
    config: any = {}
  ): MatDialogRef<T> | null {

    if (this._deviceService.isMobile()) {
      config['width'] = this._deviceService.isMobile() ? '100%' : '450px';
      config['height'] = this._deviceService.isMobile() ? '100%' : '450px';
      config['maxWidth'] = this._deviceService.isMobile() ? '100%' : '450px';
    }

    return this._dialog.open(type, config);
  }

  /**
   * Met à jour la position gps de l'utilisateur
   */
  public UpdateUserLocation(): void {
    // Get user position to save it in DB
    getLocationAsync(
      (position: any) => {
        this._userPositionGps.set(position);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
