<div class="block_info">
  <div class="block_info_title">
    <div class="block_title_text">
      <img width="40px" height="40px" src="/assets/images/account.svg" />
      Information
    </div>
  </div>
  <div *ngIf="f" class="block_info_content">
    <app-edit-field
      #firstnameField
      [editTemplate]="firstnameTemplate"
      [value]="'Pierre'"
      [name]="'Prénom'"
      [icon]="'/assets/images/identity-card.svg'"
    >
    </app-edit-field>
    <!--<app-edit-field #lastnameField (success)="updateLastname($event)" [editTemplate]="lastnameTemplate" [value]="m_myself.lastname" [name]="'Nom'" [icon]="'/assets/images/identity-card.svg'">
    </app-edit-field>
    <app-edit-field #birthdayField [canChangeVisibility]="true" [visibilityState]="m_myself.settings.birthDayVisibility" (onVisibilityChange)="onVisibilityChange($event, 'birthDayVisibility')" (success)="updateBirthdayDate($event)" [editTemplate]="birthdayTemplate" [value]="(m_myself.birthdayDate | date) + ' | ' + (m_myself.birthdayDate | dateAge) + 'ans'" [name]="'Date de naissance'" [icon]="'/assets/images/boxing-day.svg'">
    </app-edit-field>
    <app-edit-field #conjugalStatusField [canChangeVisibility]="true" [visibilityState]="m_myself.settings.conjugalStatusVisibility" (onVisibilityChange)="onVisibilityChange($event, 'conjugalStatusVisibility')" (success)="updateConjugalStatus($event)" [editTemplate]="conjugalTemplate" [value]="m_myself.conjugalStatus == 'attached' ? 'En couple' : m_myself.conjugalStatus == 'free' ? 'Célibataire' : m_myself.conjugalStatus == 'complicated' ? 'Compliqué' : 'N.C'" [name]="'Situation amoureuse'" [icon]="'/assets/images/couple.svg'">
    </app-edit-field>
    <app-edit-field #genderField [canChangeVisibility]="true" [visibilityState]="m_myself.settings.genderVisibility" (onVisibilityChange)="onVisibilityChange($event, 'genderVisibility')" (success)="updateGender($event)" [editTemplate]="genderTemplate" [value]="m_myself.sexe == 0 ? 'Femme' : m_myself.sexe == 1 ? 'Homme' : m_myself.customGender" [name]="'Genre'" [icon]="'/assets/images/gender-symbols.svg'">
    </app-edit-field>
    <app-edit-field #birthLocationField [canChangeVisibility]="true" [visibilityState]="m_myself.settings.locationVisibility" (onVisibilityChange)="onVisibilityChange($event, 'locationVisibility')" (success)="updateLocation()" [editTemplate]="locationTemplate" [value]="m_myself?.location?.name" [name]="'Lieu de résidence'" [icon]="'/assets/images/map.svg'">
    </app-edit-field>
    <app-edit-field #countryField (success)="updateCountry()" [canChangeVisibility]="false" [editTemplate]="countryTemplate" [value]="(m_myself?.country?.cca2 | countryName | async)" [name]="'Votre pays'" [icon]="'/assets/images/earth-globe.svg'">
    </app-edit-field>
    <app-edit-field #mobileField [canChangeVisibility]="true" [visibilityState]="m_myself.settings.phoneNumberVisibility" (onVisibilityChange)="onVisibilityChange($event, 'phoneNumberVisibility')" (success)="updatePhoneNumber($event)" [editTemplate]="mobileTemplate" [value]="m_myself.phoneNumber" [name]="'Numéro de portable'" [icon]="'/assets/images/mobile.svg'">
    </app-edit-field>-->

    <!--
      Country
    
    <ng-template #countryTemplate>
      <h3>Modification</h3>
      <div class="content">
        <form [formGroup]="personnalForm">
          <ng-template #rt let-r="result" let-t="term">
            <img [src]="'/assets/data/' + r.cca2.toLowerCase() + '.svg'" class="mr-1" style="width: 16px">
            <ngb-highlight [result]="r.translations.fra.common" [term]="t"></ngb-highlight>
          </ng-template>
          <input type="text" class="form-control" placeholder="Votre pays" [(ngModel)]="countrySelected" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter" />
        </form>
      </div>
      <div>
        <button (click)="countryField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="countryField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->

    <!--
      Genre
    
    <ng-template #genderTemplate>
      <h3>Modification</h3>
      <div class="content">
        <form [formGroup]="personnalForm">
          <div #sexe class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="gender">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="0"> Femme
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="1"> Homme
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="2"> Autre
            </label>
          </div>
          <input *ngIf="personnalForm.get('gender').value == 2" id="customGender" #customGender ngModel name="customGender" formControlName="customGender" class="custom-gender form-control" type="text" placeholder="Votre genre" required />
        </form>
      </div>
      <div>
        <button [disabled]="sexe.value == 2 && (f && f.customGender.errors && f.customGender.errors.invalid)" (click)="genderField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="genderField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Lieu de résidence
    
    <ng-template #locationTemplate>
      <h3>Modification</h3>
      <form [formGroup]="personnalForm">
        <div class="content">
          <p><app-address-field [placeHolder]="'Votre résidence principal'" [(hasError)]="addressError" (onAddressChange)="onChangeAddress($event)"></app-address-field></p>
          <div class="alert alert-warning">Veuillez entrer une adresse valide</div>
        </div>
      </form>
      <div>
        <button (click)="birthLocationField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="birthLocationField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Phone number
    
    <ng-template #mobileTemplate>
      <h3>Modification</h3>
      <form [formGroup]="personnalForm">
        <div class="content">
          <p><input #mobile formControlName="phoneNumber" class="form-control" type="text" placeholder="Numéro de téléphone" /></p>
          <div class="alert alert-danger">Veuillez entrer un numéro valide</div>
        </div>
      </form>
      <div>
        <button (click)="mobileField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="mobileField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Firstname
    -->
    <ng-template #firstnameTemplate>
      <h3>Modification</h3>
      <form [formGroup]="_personnalForm()">
        <div class="content">
          <p>
            <input
              #firstname
              formControlName="firstname"
              class="form-control"
              type="text"
              placeholder="Votre prénom"
            />
          </p>
          <div
            *ngIf="!_personnalForm().get('firstname')?.valid"
            class="alert alert-danger"
          >
            Veuillez entrer un prénom valide
          </div>
        </div>
      </form>
      <div>
        <button class="btn btn-sm btn-primary">Enregistrer</button>
        <button style="margin-left: 10px" class="btn btn-sm btn-danger">
          Annuler
        </button>
      </div>
    </ng-template>
    <!--
      Lastname
    
    <ng-template #lastnameTemplate>
      <h3>Modification</h3>
      <form [formGroup]="personnalForm">
        <div class="content">
          <p><input #lastname formControlName="lastname" class="form-control" type="text" placeholder="Votre nom" /></p>
          <div *ngIf="!personnalForm.get('lastname').valid" class="alert alert-danger">Veuillez entrer un nom valide</div>
        </div>
      </form>
      <div>
        <button (click)="lastnameField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="lastnameField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Birthday
    
    <ng-template #birthdayTemplate>
      <h3>Modification</h3>
      <form [formGroup]="personnalForm">
        <div class="content">
          <div>
            <input style="width: 250px; margin-right: 10px;" (click)="d.toggle()" class="form-control" formControlName="birthdayDate" placeholder="dd/mm/yyyy"
                    name="dp" ngbDatepicker #d="ngbDatepicker">
            <!--<button class="btn btn-outline-secondary img-calendar" (click)="d.toggle()" type="button"></button>
          </div>
          <!--<div class="alert alert-danger">Veuillez entrer une date valide</div>
        </div>
      </form>
      <div>
        <button (click)="birthdayField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="birthdayField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
    <!--
      Conjugal situation
    
    <ng-template #conjugalTemplate>
      <h3>Modification</h3>
      <form [formGroup]="personnalForm">
        <div class="content">
          <div #conjugalStatus class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="conjugalStatus">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'free'"> Célibataire
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'attached'"> En couple
            </label>
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="'complicated'"> Compliqué
            </label>
          </div>
        </div>
      </form>
      <div>
        <button (click)="conjugalStatusField.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="conjugalStatusField.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
  </div>
</div>
