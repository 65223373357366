<div id="message-writer">
  <div class="imagePreview" *ngIf="mediaPreview">
    <button
      class="btn btn-sm btn-secondary"
      (click)="openModal(editSelectedImage)"
    >
      Modifier les images</button
    ><br /><br />
    <div class="image-group">
      <div
        class="image"
        *ngFor="let media of medias | slice: 0:4; let index = index"
      >
        <img [src]="media.base64" />
      </div>
      <div
        class="image stackover"
        [ngStyle]="{ 'background-image': medias[medias.length - 4 + 1].data }"
        style="
          justify-content: center;
          align-items: center;
          align-content: center;
        "
        *ngIf="medias.length > 4"
      >
        + {{ medias.length - 4 }}
      </div>
    </div>
  </div>
  <div class="textbox">
    <span
      class="form-control"
      #chatTextfieldElem
      contenteditable="true"
      [(ngModel)]="m_messageTextfield"
      role="textbox"
      (keydown)="onKeyUp($event)"
      placeholder="test"
      (keydown.enter)="sendMessage($event, m_messageTextfield, true)"
      [ngStyle]="{ 'height': m_textfieldHeight, 'overflow-y': isScrollable ? 'auto' : 'hidden' }"
      class="chat_textbox"
    ></span>
    <button *ngIf="m_canSend" class="sendButton" [ngStyle]="{'right': isScrollable ? '19px' : '2px'}" (click)="sendMessage($event, m_messageTextfield)">
      <img src="/assets/images/send.png" />
    </button>
  </div>

  <!--<div class="send_message_icon" title="Envoyer le message" (click)="sendMessage()"></div>-->
  <ul class="chat_tools">
    <!--<li title="Créer un groupe"><img src="../../../assets/images/group.png" /></li>-->
    <li
      [container]="'body'"
      [openDelay]="400"
      placement="top"
      ngbTooltip="Ajouter une emote"
      (click)="openEmojiPopup()"
    >
      <img src="../../../assets/images/emote.svg" />
    </li>
    <li
      [container]="'body'"
      *ngIf="canSendMedia"
      (click)="chatFiles.click()"
      [openDelay]="400"
      placement="top"
      ngbTooltip="Envoyer une image"
    >
      <img src="../../../assets/images/photo_upload.svg" />
      <input
        type="file"
        multiple
        #chatFiles
        style="display: none"
        (change)="handleFiles($event)"
      />
    </li>
    <li
      [container]="'body'"
      *ngIf="canMakeCall"
      (click)="openCallWindow()"
      [openDelay]="400"
      placement="top"
      ngbTooltip="Lancer un appel vidéo"
    >
      <img src="../../../assets/images/mobile/047-video call.svg" />
    </li>
  </ul>
</div>
<app-emoticon-choice
  (textfieldChange)="onEmoteAdded($event)"
  [textfield]="m_messageTextfield"
  (showMenuChange)="this.showEmojiPopup = $event"
  [showMenu]="showEmojiPopup"
></app-emoticon-choice>

<ng-template #editSelectedImage let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Modifier vos images</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="imageContainer">
      <div class="image" *ngFor="let image of medias">
        <button
          container="body"
          [ngbTooltip]="'Supprimer cette images'"
          class="btn btn-danger btn-sm removeFile"
          (click)="removeMedia(image)"
        >
          &times;
        </button>
        <img [src]="image.base64" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary"
      type="button"
      (click)="modal.dismiss('close')"
    >
      Fermer
    </button>
    <button class="btn btn-primary" (click)="addFileBtn.click()">
      Envoyer une photo</button
    ><input
      #addFileBtn
      (change)="handleFiles($event)"
      style="display: none"
      type="file"
      multiple
    />
  </div>
</ng-template>
