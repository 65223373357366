<div class="block_info">
  <div class="block_info_title">
    <div class="block_title_text">
      <img width="40px" height="40px" src="/assets/images/layout.svg" />
      Interface
    </div>
  </div>
  <div class="block_info_content">
    <!--<app-edit-field #uiLang (success)="updateLangue($event)" [editTemplate]="uiLangTemplate" [value]="m_myself?.language?.uiLang" [name]="'Langue de l\'interface'" [icon]="'/assets/images/languages.svg'">
    </app-edit-field>-->

    <!--
      Lieu de résidence
    
    <ng-template #uiLangTemplate>
      <h3>Modification</h3>
      <form [formGroup]="uiForm">
        <div class="content">
          <p>
            <select class="form-control" formControlName="langue">
              <option selected>Selectionnez une langue...</option>
              <option *ngFor="let langue of langues">{{langue}}</option>
            </select>
          </p>
          <div class="alert alert-warning">Veuillez entrer une langue valide</div>
        </div>
      </form>
      <div>
        <button (click)="uiLang.success()" class="btn btn-sm btn-primary">Enregistrer</button>
        <button (click)="uiLang.close()" style="margin-left:10px;" class="btn btn-sm btn-danger">Annuler</button>
      </div>
    </ng-template>-->
  </div>
</div>
