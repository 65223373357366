import { ApiService } from 'src/app/core/services/api.service';
import { Component, OnInit, Input, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from '../camera/components/camera/camera.component';
import { PublicDataPipe } from 'src/app/core/utils/tools';
import { HeaderUserComponent } from './header-user/header-user.component';
import { WritePostComponent } from './write-post/write-post.component';
import { WriteCommentComponent } from './write-comment/write-comment.component';
import { CommentComponent } from './comment/comment.component';
import { Post, PageResult } from 'src/app/features/user/models/user';
import { UserService } from 'src/app/features/user/services/user.service';
import { UserLabelComponent } from '../../utils/user-label/user-label.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    HeaderUserComponent,
    WritePostComponent,
    WriteCommentComponent,
    CommentComponent,
    UserLabelComponent
  ]
})
export class PostComponent implements OnInit {

  // Services
  public notif = inject(ToastrService);
  private userService = inject(UserService);
  private apiService = inject(ApiService);

  // Variables
  @Input("post") post: Post | null = null;
  @Input("ownerId") ownerId: number = 0;
  @Input("ownerType") ownerType: string = "user";
  @Input("smallSize") smallSize: boolean = false;
  @Input("showMedia") showMedia: boolean = true;
  public showCreateComment: boolean = false;
  public m_editText: boolean = false;
  public iLike: boolean = false;
  public editTextValue: string = "";
  public likeCounter: number = 0;
  public canAddComment: boolean = true;
  public likePictureSize: number = 2;
  public likeNameSize: number = 2;
  public commentsIsLoaded: boolean = true;
  public messageOffset: number = 0;
  public commentsPageResult: PageResult | undefined = {
    data: null,
    page: 0,
    page_size: 6,
    total_records: 0
  };
  public commentsList: any[] = [];

  constructor() { }

  /**
   * Init
   */
  ngOnInit() {
    var self = this;

    if (this.smallSize)
    {
      this.likePictureSize = 2;
      this.likeNameSize = 2;
    } else {
      this.likePictureSize = 4;
      this.likeNameSize = 2;
    }

    if (this.ownerType === "event")
    {
      this.canAddComment = false;
    }

    // Calcul le nombre de like
    this.countLike();
  }

  /**
   * Calcul le nombre de like du poste
   */
  public countLike()
  {
    let i = 0;
    if (this.post != null && this.post.likes != null)
    {
      this.post.likes.forEach(like => {
        if (like?.user?.ID == this.userService?.myself()?.ID && like.state == "like")
        {
          this.iLike = true;
          i++;
        }
        else if (like?.user?.ID != this.userService?.myself()?.ID && like.state == "like")
        {
          i++;
        }
      });
    }

    this.likeCounter = i;
  }

  /**
   * Ouvre la liste des commentaire
   */
  public openComment()
  {
    var self = this;


    if (this.showCreateComment)
      this.showCreateComment = false
    else
    {
      this.showCreateComment = true;
    }
  }

  public onCommentCreate(comment: any)
  {
    var self = this;

    if (this.commentsList == null && this.post?.ID != null)
    {
      this.apiService.getCommentsFromPostId(this.post?.ID, 0, 6).subscribe((response) => {
        setTimeout(() => {
          if (response.status) {
            if (self.commentsList == null)
              self.commentsList = response?.pageResult?.data;
            else
              self.commentsList.push(...response?.pageResult?.data);

              if (self?.commentsPageResult?.page != null)
              {
                self.commentsPageResult = response.pageResult;
                if (self?.commentsPageResult?.page != null)
                  self.commentsPageResult.page++;
              }
            }

          self.commentsIsLoaded = true;
        }, 500);
      });
    } else {
      this.commentsList.unshift(comment);
    }
  }

  public loadComments()
  {
    var self = this;
    this.commentsIsLoaded = false;
    if (this.post?.ID)
    {
      this.apiService.getCommentsFromPostId(this.post?.ID, this.commentsPageResult?.page, 6).subscribe((response) => {
        setTimeout(() => {
          if (response.status) {
            if (self.commentsList == null)
              self.commentsList = response?.pageResult?.data;
            else
              self.commentsList.push(...response?.pageResult?.data);

            if (self?.commentsPageResult?.page != null)
              self.commentsPageResult = response.pageResult;
            if (self?.commentsPageResult?.page != null)
              self.commentsPageResult.page++;
          }

          self.commentsIsLoaded = true;
        }, 500);
      });
    }
  }

  /**
   * Ouvre l'editeur de text pour editer le post
   * @param editText Indique si on ouvre l'éditeur de text
   */
  public editText(editText: boolean)
  {
    this.m_editText = editText;
  }

  /**
   * Met à jour le post et ferme la fenetre d'edition
   */
  public updatePost()
  {
    this.m_editText = false;
    this.apiService.UpdatePost(this.post).subscribe((data) => {
      this.notif.success('Votre publication est à jour')
    });
  }

  /**
   * Ajoute ou supprime un like
   */
  public updateLike()
  {
    var self = this;
    if (this.iLike && this.post?.ID)
    {
      // Remove un like
      this.apiService.AddLikeToPost(this.post?.ID, "unlike").subscribe((response) => {
        if (response.status)
        {
          self.iLike = false;

          if (self.post?.likes)
            self.post?.likes.splice(self.post?.likes.length-1, 1);

          // Calcul de nouveau le nombre de like
          self.countLike();
        }
      });
    }
    else
    {
      // Ajoute un like
      if (this.post?.ID)
      this.apiService.AddLikeToPost(this.post.ID, "like").subscribe((response) => {
        if (response.status)
        {
          self.iLike = true;

          if (self.post?.likes)
            self.post.likes.push({
              state: "like",
              user: self.userService.myself()
            });

          // Calcul de nouveau le nombre de like
          self.countLike();
        }
      });
    }
  }
}
