import { ApiService } from 'src/app/core/services/api.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { PublicDataPipe } from 'src/app/core/utils/tools';
import { CameraComponent } from '../../camera/components/camera/camera.component';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserLabelComponent } from 'src/app/core/utils/user-label/user-label.component';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WebcamModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CameraComponent,
    PublicDataPipe,
    UserLabelComponent
  ]
})
export class CommentComponent implements OnInit {

  // Services
  public notif = inject(ToastrService);
  private userService = inject(UserService);
  private apiService = inject(ApiService);
  
  // Variables
  @Input("comment") comment: any;
  public isLastComment = false;
  public iLike: boolean = false;
  public likeCounter: number = 0;

  constructor() { }

  ngOnInit() {
    // Calcul le nombre de like
    this.countLike();
  }

  public countLike()
  {
    let i = 0;
    if (this.comment != null && this.comment.likes != null)
    {
      this.comment.likes.forEach((like: any) => {
        if (like.user == null)
          return;

        if (like.user.ID == this.userService?.myself()?.ID && like.state == "like")
        {
          this.iLike = true;
        }
        if (like.state == "like")
        {
          i++;
        }
      });
    }

    this.likeCounter = i;
  }

  public updateLike()
  {
    var self = this;
    if (this.iLike)
    {
      if(this.userService?.myself()?.ID == null)
        return;

      this.apiService.AddLikeToComment(this.userService.myself().ID, this.comment.ID, "unlike").subscribe((response) => {
        if (response.status)
        {
          self.iLike = false;
          self.likeCounter--;
          self.comment.likes.splice(self.comment.likes.length-1, 1);

          // Calcul de nouveau le nombre de like
          self.countLike();
        }
      });
    }
    else
    {
      if(this.userService?.myself()?.ID == null)
        return;

      this.apiService.AddLikeToComment(this.userService.myself().ID, this.comment.ID, "like").subscribe((response) => {
        if (response.status)
        {
          self.iLike = true;
          self.likeCounter++;

          self.comment.likes.push({
            state: "like",
            user: self.userService.myself
          });

          // Calcul de nouveau le nombre de like
          self.countLike();
        }
      });
    }
  }
}
