<div class="header" *ngIf="m_currentPost?.user != null">
  <div class="picture">
    <img src="{{m_post?.user?.profileImage | publicData}}" width="40px" height="40px" />
  </div>
  <div class="name" *ngIf="m_post?.medias != null">
    <p><b><user-label [user]="m_post?.user"></user-label></b>
      <span *ngIf="m_post?.type == 'default' && m_post?.medias == null"> a mis à jour sa <span class="link"><b>pensée</b></span></span>
      <span *ngIf="m_post?.type == 'user_banner' && m_post?.medias != null && m_post?.user?.profileImage != null"> a mis à jour sa <span class="link"><b>bannière</b></span></span>
      <span *ngIf="m_post?.type == 'user_miniature' && m_post?.medias != null && m_post?.user?.profileImage != null"> a mis à jour sa <span class="link"><b>miniature</b></span></span>
      <span *ngIf="m_post?.type == 'share_event' && m_post?.event != null && m_post?.event?.ID != null"> a partagé un <span class="link" [routerLink]="['event', m_post?.event?.ID]"><b>événement</b></span></span>
    </p>
  </div>
  <div class="created_at">
    <p>{{m_post?.CreatedAt}}</p>
  </div>
  <div [ngClass]="{'menuActive': m_paramListOpen}" (mouseleave)="onParamHover(false)" *ngIf="userService?.myself()?.ID == m_post?.user?.ID" class="about_parameter">
    <img (mouseenter)="onParamHover(true)" src="../../../assets/images/setup.svg" width="15px" height="15px" />
    <div class="param_list" *ngIf="m_paramListOpen">
      <ul>
        <li *ngIf="userService?.myself()?.ID != m_post?.user?.ID">Partager</li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID" (click)="changeVisibility()">Visible par :
          <span *ngIf="m_post?.ownerType == 'user'">
            {{m_post?.visibleBy == 'friend' ? 'Ami(e)s' : 'Tout le monde'}}
          </span>
          <span *ngIf="m_post?.ownerType == 'community' || m_post?.ownerType == 'event'">
            {{m_post?.visibleBy == 'member' ? 'Membres' : 'Tout le monde'}}
          </span>
        </li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID" (click)="updateText()">Modifier</li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID && m_post?.showOnTimeline" (click)="hidePost(m_post)">Retirer du mur</li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID && !m_post?.showOnTimeline" (click)="hidePost(m_post)">Afficher sur le mur</li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID && m_post?.ownerType == 'share_event'" (click)="openModal(deleteEventPostModal)">Supprimer de votre page</li>
        <li *ngIf="userService?.myself()?.ID == m_post?.user?.ID && m_post?.type == 'default'" (click)="openModal(deletePostModal)">Supprimer de votre page</li>
      </ul>
    </div>
  </div>
</div>
<ng-template  #deletePostModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Suppression de votre poste</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Êtes-vous sûr de vouloir supprimer ce poste de votre page ?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Annuler</button>
    <button class="btn btn-danger" type="button" (click)="deletePost()">Supprimer</button>
  </div>
</ng-template>
<ng-template  #deleteEventPostModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Suppression de votre partage</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Êtes-vous sûr de vouloir supprimer ce partage de votre page ?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Annuler</button>
    <button class="btn btn-danger" type="button" (click)="deleteEventPost()">Supprimer</button>
  </div>
</ng-template>