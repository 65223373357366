import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MapService } from 'ngx-mapbox-gl';
import { ApiService } from 'src/app/core/services/api.service';
import { AppService } from 'src/app/core/services/app.service';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-confidentiality',
  templateUrl: './confidentiality.component.html',
  styleUrls: ['./confidentiality.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
})
export class ConfidentialityComponent implements OnInit {
  // Services
  public _appService = inject(AppService);
  public _apiService = inject(ApiService);
  public _userService = inject(UserService);
  public _mapService = inject(MapService);
  public _formBuilder = inject(FormBuilder);
  public _changeDetectorRef = inject(ChangeDetectorRef);

  // Variables
  public _confidentialityForm = signal<FormGroup>(
    this._formBuilder.group(
      this._formBuilder.group({
        notificationZoneEnable: [
          this._userService.myself()?.settings?.notifZoneEnable,
          [Validators.required],
        ],
        notifZoneDistance: [
          this._userService.myself()?.settings?.notifZoneDistance,
          [Validators.required],
        ],
        profileFriendCanPost: [
          this._userService?.myself()?.settings?.profileFriendCanPost,
          [Validators.required],
        ],
        profileFacebookLink: [
          this._userService?.myself()?.settings?.profileFacebookLink,
          [Validators.required],
        ],
        profileTwitterLink: [
          this._userService?.myself()?.settings?.profileTwitterLink,
          [Validators.required],
        ],
        profileInstagramLink: [
          this._userService.myself()?.settings?.profileInstagramLink,
          [Validators.required],
        ],
        profileWhoSeeFuturPost: [
          this._userService.myself()?.settings?.profileWhoSeeFuturPost,
          [Validators.required],
        ],
        profileWhoSendRequestFriend: [
          this._userService?.myself()?.settings?.profileWhoSendRequestFriend,
          [Validators.required],
        ],
        forumSignature: [
          this._userService?.myself()?.settings?.forumSignature,
          [Validators.required],
        ],
      })
    )
  );

  constructor() {}

  get f() {
    return this._confidentialityForm().controls as any;
  }

  ngOnInit(): void {}
  /*
  updateProfileFriendCanPost(value)
  {
    this._userService.myself.settings.profileFriendCanPost = this.confidentialityForm.get("profileFriendCanPost").value;;
    this._userService.SaveAndShareMyself();
  }

  updateFacebookLink(value)
  {
    this.userService.myself.settings.profileFacebookLink = this.confidentialityForm.get("profileFacebookLink").value;;
    this._userService.SaveAndShareMyself();
  }

  updateTwitterLink(value)
  {
    this.userService.myself.settings.profileTwitterLink = this.confidentialityForm.get("profileTwitterLink").value;;
    this._userService.SaveAndShareMyself();
  }

  updateInstagramLink(value)
  {
    this.userService.myself.settings.profileInstagramLink = this.confidentialityForm.get("profileInstagramLink").value;;
    this._userService.SaveAndShareMyself();
  }

  updateWhoSeeFuturPost(value)
  {
    this.userService.myself.settings.profileWhoSeeFuturPost = this.confidentialityForm.get("profileWhoSeeFuturPost").value;;
    this._userService.SaveAndShareMyself();
  }

  updateWhoSendRequestFriend(value)
  {
    this.userService.myself.settings.profileWhoSendRequestFriend = this.confidentialityForm.get("profileWhoSendRequestFriend").value;;
    this._userService.SaveAndShareMyself();
  }

  updateForumSignature(value)
  {
    this.userService.myself.settings.forumSignature = this.confidentialityForm.get("forumSignature").value;;
    this._userService.SaveAndShareMyself();
  }*/
}
