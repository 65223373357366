import {
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapService } from 'ngx-mapbox-gl';
import { ApiService } from 'src/app/core/services/api.service';
import { AppService } from 'src/app/core/services/app.service';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  // Services
  public _appService = inject(AppService);
  public _apiService = inject(ApiService);
  public _userService = inject(UserService);
  public _mapService = inject(MapService);
  public _formBuilder = inject(FormBuilder);
  public _changeDetectorRef = inject(ChangeDetectorRef);

  // Variables
  public _confidentialityForm = signal<FormGroup>(
    this._formBuilder.group({
      notificationZoneEnable: [
        this._userService.myself()?.settings?.notifZoneEnable,
        [Validators.required],
      ],
      notifZoneDistance: [
        this._userService.myself()?.settings?.notifZoneDistance,
        [Validators.required],
      ],
    })
  );

  constructor() {}

  get f() {
    return this._confidentialityForm().controls as any;
  }

  ngOnInit(): void {}
  /*
  toggleNotifZone(value)
  {
    this._userService.myself.settings.notifZoneEnable = this.notificationForm.get("notificationZoneEnable").value;
    this._userService.SaveAndShareMyself();
  }

  updateNotifZoneDistance(value)
  {
    this._userService.myself.settings.notifZoneDistance = this.notificationForm.get("notifZoneDistance").value;;
    this._userService.SaveAndShareMyself();
  }*/
}
