import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActionPerformed,
  LocalNotificationSchema,
  LocalNotifications,
  PermissionStatus,
} from '@capacitor/local-notifications';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  // Services
  private _snackBar = inject(MatSnackBar);

  // Variables
  private _notificationsEnabled = signal(false);

  constructor() {
    this.InitPushNotification();
  }

  ngOnDestroy(): void {
    LocalNotifications.removeAllDeliveredNotifications();
    LocalNotifications.removeAllListeners();
  }

  /**
   * Lance l'initialisation du serveur de notification push LOCAL
   * S'abbone au different evenement et demande les droits d'accès à l'user
   */
  private InitPushNotification() {
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //console.log('Notification clicked:', notification);
      }
    );

    LocalNotifications.addListener(
      'localNotificationReceived',
      (notification: LocalNotificationSchema) => {
        //console.log('Notification triggered:', notification);
      }
    );

    LocalNotifications.checkPermissions().then(
      (permissions: PermissionStatus) => {
        switch (permissions.display) {
          case 'granted':
            this._notificationsEnabled.set(true);
            break;
          case 'prompt-with-rationale':
            this._notificationsEnabled.set(true);
            break;
          case 'prompt':
            this._notificationsEnabled.set(true);
            break;
          case 'denied':
            this._notificationsEnabled.set(false);
            break;
        }
      }
    );

    LocalNotifications.requestPermissions().then(
      (permissions: PermissionStatus) => {
        switch (permissions.display) {
          case 'granted':
            this._notificationsEnabled.set(true);
            LocalNotifications.createChannel({
              id: 'hotspots',
              name: 'Hotspots',
              description: 'Hotspots',
              importance: 4,
            }).catch((error: any) => {
              console.debug(error);
            });
            break;
          case 'prompt-with-rationale':
            this._notificationsEnabled.set(true);
            break;
          case 'prompt':
            this._notificationsEnabled.set(true);
            break;
          case 'denied':
            this._notificationsEnabled.set(false);
            break;
        }
      }
    );
  }

  /**
   * Ouvre une notification simple
   * @param message Message
   * @param action Action
   */
  public simplyNotification(
    message: string,
    action: string = '',
    duration: number = 3000
  ) {
    this._snackBar.open(message, action, { duration: duration });
  }

  /**
   * Indique si les notification sont activée sur le device
   */
  get notificationsEnabled(): boolean {
    return this._notificationsEnabled();
  }

  /**
   * Shows a local notification with the given title and body.
   *
   * @param {string} title - The title of the notification.
   * @param {string} body - The body of the notification.
   */
  public showLocalPushNotification(title: string, body: string) {
    if (!this._notificationsEnabled()) {
      return;
    }

    LocalNotifications.schedule({
      notifications: [
        {
          id: parseInt(uuidv4(), 16),
          title,
          body,
          largeBody: body,
          largeIcon: 'res://drawable/logo_inscruted_image',
          extra: { foo: 'bar' },
        },
      ],
    });
  }
}
